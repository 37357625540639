// Component
import { MainFooterComponent } from './partials/bottombar/seo/main-footer/main-footer.component';
import { BaseComponent } from './base/base.component';
import { BottombarComponent } from './partials/bottombar/bottombar.component';
import { SidebarComponent } from './partials/sidebar/sidebar.component';
import { TopbarComponent } from './partials/topbar/topbar.component';
import { BaseInnerComponent } from './partials/base-inner/base-inner.component';
import { SliderComponent } from './partials/slider/slider.component';
import { SidePanelsComponent } from './partials/sidepanels/sidepanels.component';
import { GameMenuComponent } from './partials/game-menu/game-menu.component';
import { SportsFooterComponent } from './partials/bottombar/seo/sports-footer/sports-footer.component';
import { CasinoFooterComponent } from './partials/bottombar/seo/casino-footer/casino-footer.component';
import { SlotsFooterComponent } from './partials/bottombar/seo/slots-footer/slots-footer.component';
import { EsportsFooterComponent } from './partials/bottombar/seo/esports-footer/esports-footer.component';
import { DynamicFooterComponent } from './partials/dynamic-footer/dynamic-footer.component';
import { JackpotSliderComponent } from './partials/jackpot-slider/jackpot-slider.component';
// Angular
import { NgModule } from '@angular/core';
// Module
import { SharedModule } from '../shared/shared.module';
import { LivechatWidgetModule } from '@livechat/angular-widget';
import { MatBadgeModule } from '@angular/material/badge';
import { Ng2OdometerModule } from 'ng2-odometer';

const components = [
  BaseComponent,
  BaseInnerComponent,
  BottombarComponent,
  SidebarComponent,
  TopbarComponent,
  SliderComponent,
  SidePanelsComponent,
  GameMenuComponent,
  MainFooterComponent,
  SportsFooterComponent,
  CasinoFooterComponent,
  SlotsFooterComponent,
  EsportsFooterComponent,
  DynamicFooterComponent,
  JackpotSliderComponent
];

@NgModule({
  declarations: [
    ...components,
  ],
  exports: [
    ...components
  ],
  imports: [
    SharedModule,
    LivechatWidgetModule,
    MatBadgeModule,
    Ng2OdometerModule.forRoot()
  ]
})
export class ViewsModule { }
