import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, DoCheck, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  NavigationEnd,
  Router
} from '@angular/router';
import { MemberBalance } from '@core/models/member-balance.model';
import { RequestReceipt } from '@core/models/request-receipt.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { BankAccountHttpService } from '@core/services/bank-account-http.service';
import { DashboardHttpService } from '@core/services/dashboard-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { LoadingService } from '@core/services/loading-service.service';
import { MenuBarHttpService } from '@core/services/menu-bar-http.service';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import { PortalMessageHttpService } from '@core/services/portal-message-http.service';
import { RequestReceiptHttpService } from '@core/services/request-receipt-http.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { WebsocketService } from '@core/services/websocket.service';
import { AuthActions } from '@core/store/auth/auth-action.types';
import * as fromAuthActions from '@core/store/auth/auth.actions';
import { logout } from '@core/store/auth/auth.actions';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { environment } from '@env/environment';
import { Store, select } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ForgotPasswordModalComponent } from '@shared/forgot-password-modal/forgot-password-modal.component';
import { LanguageModalComponent } from '@shared/language-modal/language-modal.component';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { SignupModalComponent } from '@shared/signup-modal/signup-modal.component';
import { WalletModalComponent } from '@shared/wallet-modal/wallet-modal.component';
import { PredictionHttpService } from '@views/modules/game/services/prediction-http.service';
import { AppState } from 'app/store/reducers';
import svgIconList from 'assets/icons.json';
import * as moment from 'moment';
import { Observable, Subscription, of } from 'rxjs';
import { delay, filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, AfterViewInit, DoCheck, AfterViewInit {
  svg: any = svgIconList;

  mode: string = window.mode;
  isLoggedIn$: Observable<boolean>;
  memberUsername$ = this.dashboardHttpService.memberUsername$;
  userFlag: string;
  currentCountryCode = localStorage.getItem('country_code');
  currentLangCode = localStorage.getItem('language_code') == 'ZH' ? '中文' : localStorage.getItem('language_code');

  playtechPage = localStorage.getItem('playtechCategory') !== null ? true : false;
  availableProvider = JSON.parse(localStorage.getItem('available_providers'));
  popup_ids = localStorage.getItem('popup_ids') ?? [];

  // dateTime: string;
  unreadMessages$ = this.portalMessageHttpService.messageCount$;
  messages$ = this.portalMessageHttpService.messages$;
  newMessage = false;

  mobileDomain: string;
  desktopDomain: string;
  counter: number;
  timer: any;

  showMiniLogin = false;
  formMiniLogin: FormGroup;
  disableButton = false;
  checkValidity = false;
  availableCountryAndLanguages = this.dropdownHttpService.availableCountryAndLanguages;
  currentDomain = JSON.parse(sessionStorage.getItem('mainDomainBlocked')) ? this.getAltDomain() : environment.domainName;
  showKYCGateKeeper: boolean;
  memberKYC: string;
  memberGroup: string;
  isPredictionTabClicked = true;
  verificationRejectedPending: boolean;

  fixed = false;

  currentDateUTC = moment().utc().format('YYYY-MM-DD HH:mm:ss');
  showTheme = false;
  isLaunchGamePage = false;

  themes = {
    "CNY 2025": {
      logo: 'assets/integrations/theme/cny-2025.gif',
      startDate: '2025-01-22',
      endDate: '2025-02-05',
    }
  }

  menuLists;
  menuMaxItems = 9;

  altName = environment.sitePrefix.toUpperCase();
  hostname = (window.location.hostname).replace(/^([w]{3}.)(?=[a-z0-9]+)/, '');

  affiliateDomain = this.dropdownHttpService.affiliateDomain;
  affiliateUrl = '';

  onMaintenancePage = false;

  sitePrefix = environment.sitePrefix;

  newRequestIncoming = this.requestReceiptHttpService.newRequestIncoming;
  depositData: RequestReceipt = null;

  predictionMenuHidden: boolean = false;
  newMenuHidden: boolean = false;
  memberGroupImage = null;
  memberGroupName = null;
  memberName = null;

  userProfileDetail = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : null;
  walletBalance: MemberBalance;

  @HostListener('window:scroll')
  pageScroll(e: Event) {
    this.stickyGameMenu();
  }
  private subscriptions: Subscription[] = [];
  private subscription = new Subscription();
  memberGroupColor: any;
  memberBalanceIsLoading: number = 0;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog,
    private authService: AuthHttpService,
    private store: Store<AppState>,
    private portalMessageHttpService: PortalMessageHttpService,
    private portalMemberProfile: PortalMemberProfileHttpService,
    private dashboardHttpService: DashboardHttpService,
    private router: Router,
    public loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    private fb: FormBuilder,
    private dropdownHttpService: DropdownHttpService,
    private walletHttpService: WalletHttpService,
    private predictionHttpService: PredictionHttpService,
    private menuBarHttpService: MenuBarHttpService,
    private requestReceiptHttpService: RequestReceiptHttpService,
    private bankAccountHttpService: BankAccountHttpService,
    private websocketService: WebsocketService,
    private cdr: ChangeDetectorRef,
    private loadingService: LoadingService,
  ) {
    if (window.location.pathname !== '/launch') {}
    else {
      this.isLaunchGamePage = true;
    }
    // Subscribe to loading service providerBalancesLoadingBehaviourSubject to get update on loading status
    this.subscriptions.push(
      this.loadingService.memberBalanceLoadingBehaviourSubject.subscribe(val => {
        this.memberBalanceIsLoading = val;
      })
    );
    // Subscribe to get push update for member balance
    this.subscriptions.push(
      this.walletHttpService.memberBalance.subscribe(res => {
        this.walletBalance = res;
      })
    );
  }

  ngOnInit() {
    setTimeout(() => {
      this.onMaintenancePage = (window.location.pathname) == '/maintenance' ? true : false;
    }, 500);

    this.eventEmitterService.updateMemberGroupDetailsEmitter.subscribe(() => {
      setTimeout(() => {
        this.checkMemberGroupLevel();
        this.userProfileDetail = JSON.parse(localStorage.getItem('user_data'));
      }, 500);
    });
    
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.getUserKYC();
    });

    setTimeout(() => { // delayed to get the country on domain
      if (!Object.keys(this.availableCountryAndLanguages[this.currentDomain]).includes(this.currentCountryCode)) {
        this.currentCountryCode = 'MY';
        localStorage.setItem('country_code', this.currentCountryCode);
        this.getFlag();
      }
      this.getMobileDomain();
      this.setAltName();
      this.getAffiliateUrl();
    }, 2000)

    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));

    if (isLoggedIn) {
      this.getFlag();
    }

    if (!this.authService.isTokenExpired()) {
      const userData = JSON.parse(localStorage.getItem('user_data'));
      this.dashboardHttpService.refreshMemberName(userData.username);
    }

    if (this.eventEmitterService.subsVar === undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.reloadMessageEmitter.subscribe((name: any) => { });
    }

    this.eventEmitterService.topBarTabVar = this.eventEmitterService.topBarTabEmitter.subscribe(() => {
      this.availableProvider = JSON.parse(localStorage.getItem('available_providers'));
    });

    this.eventEmitterService.flagEmitter.subscribe((name: any) => {
      this.currentCountryCode = localStorage.getItem('country_code');
      this.getFlag();
    });

    this.eventEmitterService.verificationTimerEmitter.subscribe((name: string) => {
      if (sessionStorage.getItem('OTP_timer') !== null) {
        this.onTimer(Number(sessionStorage.getItem('OTP_timer')) - 1);
      } else {
        this.onTimer(60 - 1);
      }
    });

    this.eventEmitterService.buttonEmitter.subscribe(() => {
      this.disableButton = false;
    });

    this.eventEmitterService.topbarEventClicked.subscribe(res => this.isPredictionTabClicked = res);

    this.portalMessageHttpService.messageSubject.subscribe(res => {
      this.newMessage = res.event_type === 'new' ? true : false;
    });

    this.initializeMiniLoginForm();
    this.isLoggedIn$.subscribe(res => {
      if (res) {
        this.newRequestIncoming.subscribe(() => {
          this.depositData = RequestReceiptHttpService.requestReceipt;
        })
        setTimeout(() => {
          var userdata = JSON.parse(localStorage.getItem('user_data'));
          const memberAccountId = userdata.id;

          this.checkMemberGroupLevel();

          this.getUserKYC();
          this.getMenuBar();

          console.log('if res')

          this.getRejectedVerification(false);
          this.eventEmitterService.updateverificationEmitter.subscribe(() => {
            this.getRejectedVerification(true);
          });
          
          // check whether to show noti for "Prediction" tab
          this.predictionHttpService.checkMemberPredictionTab(memberAccountId).subscribe(res => {
            this.isPredictionTabClicked = res.clicked;
          });

          // No need to call websocket when in /launch page
          if (window.location.pathname !== '/launch') {
            this.portalMessageHttpService.getUnread().subscribe(() => {
              this.portalMessageHttpService.messageWebsocket(memberAccountId);
            });

            this.memberWebsocket(memberAccountId);
            this.vipEligibilityWebsocket(memberAccountId);
            this.updateMemberInfoWebsocket(memberAccountId);
            this.updateMemberEventClickWebsocket(memberAccountId);
            this.createEventSendNotificationWebsocket();
          }

          this.eventEmitterService.updateCountryAndLanguageEmitter.subscribe(() => {
            this.checkMemberGroupLevel();
          });

        }, 500);
        if (this.router.url === '/member/message') {
          this.newMessage = false;
        }
      } else {
        this.getMenuBar();
        console.log('else')
      }
    });

    Object.keys(this.themes).forEach(key => {
      this.themes[key].started = this.festiveStarted(this.themes[key].startDate);
      this.themes[key].end = this.festiveEnd(this.themes[key].endDate);
      this.themes[key].data = [];
      if (this.themes[key].started && !this.themes[key].end) {
        this.showTheme = true;
      }
    });

    // Resize topbar as user adjusts
    window.addEventListener('resize', function (event) {
      this.setMenuMaxItems();
    }.bind(this), true);
  }

  ngAfterViewInit() { }

  private setAltName() {
    let countryCode = null;
    Object.keys(this.availableCountryAndLanguages[this.currentDomain]).forEach(key => {
      if (this.availableCountryAndLanguages[this.currentDomain][key][environment.domainEnv].includes(this.hostname)) {
        countryCode = key;
      }
    });

    if (countryCode === 'SG' && environment.sitePrefix == 'bp77') {
      this.altName = 'Best 🕹️Online Casino🎰 in Singapore | BP77';
    }
  }

  stickyGameMenu() {
    this.fixed = false;
    if (this.document.documentElement.scrollTop > 91) {
      this.fixed = true;
    }
  }

  onTimer(startTime: number) {
    this.counter = startTime;
    window.clearInterval(this.timer);
    this.timer = setInterval(() => {
      sessionStorage.setItem('OTP_timer', (this.counter--).toString());
      if (this.counter === 0) {
        window.clearInterval(this.timer);
        sessionStorage.removeItem('OTP_timer');
      }
    }, 1000);
  }

  ngDoCheck() {
    window.onscroll = () => { scrollFunction(); };
    function scrollFunction() {
      if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
        if (document.getElementById('header-wrap') !== null) {
          document.getElementById('header-wrap').style.background = '#0E131C';
        }
      }
    }
  }

  submitMiniLogin() {
    //this.disableButton = true;
    this.checkValidity = true;
    if (this.formMiniLogin.valid) {
      this.loadingBar.start();
      const { username, password } = this.formMiniLogin.value;

      this.authService.login(username, password).pipe(
        tap((res) => {
          this.eventEmitterService.onUpdateUsername(username);
          if (res.data.user.country_code !== this.currentCountryCode) {
            this.eventEmitterService.onResetAnnouncementEmitter();
          }
          this.store.dispatch(fromAuthActions.login({
            user: res.data.user,
            token: res.data.token,
          }));

          this.getMobileDomain();
          localStorage.setItem('verified_number', res.data.user.phone_validated);
          if (res.data.user.name !== '' && res.data.user.email !== '') {
            this.walletHttpService.getMemberBalance().subscribe(wallet => {
              if (wallet.balance <= 0) {
                this.eventEmitterService.onAvailableProviderEmitter();
                if (res.data.user.country_code === this.currentCountryCode) {
                  this.router.navigate(['/member']);
                } else {
                  window.location.href = '/member';
                }
              }
              of(null).pipe(
                delay(1000), tap(() => this.eventEmitterService.onTopBarTabEmitter()
                )).subscribe();
            });
          }
          this.loadingBar.complete();
          //this.disableButton = false;
          this.checkValidity = false;
        })
      ).subscribe();
    }
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authService.forceLowerCaseInputControl(this.formMiniLogin, controlName, event);
  }

  onOpenDialog(type: string) {
    switch (type) {
      case 'login':
        this.openDialogBy(LoginModalComponent, 'login');
        break;

      case 'signup':
        this.openDialogBy(SignupModalComponent, 'signup');
        break;

      case 'wallet':
        this.openDialogBy(WalletModalComponent, 'wallet');
        break;

      case 'language':
        this.openDialogBy(LanguageModalComponent, 'language');
        break;

      case 'forgotpassword':
        this.openDialogBy(ForgotPasswordModalComponent, 'forgotpassword');
        break;
    }

  }

  onLogout() {
    this.loadingBar.start();
    this.authService.logout().subscribe(() => {
      window.location.href = this.mode == 'Members Only' ? '/login' : '/';
      this.store.dispatch(AuthActions.logout());
    });
  }

  // onHover(icon: any, mouseIn?: boolean) {
  //   switch (icon) {
  //     case 'wallet':
  //       this.walletIcon = mouseIn ? 'assets/images/member/wallet.png' : 'assets/images/topnav/wallet.png';
  //       break;
  //     case 'portal':
  //       this.portalIcon = mouseIn ? 'assets/images/topnav/account-active.png' : 'assets/images/topnav/account.png';
  //       break;
  //     case 'message':
  //       this.messageIcon = mouseIn ? 'assets/images/topnav/message-active.png' : 'assets/images/topnav/message.png';
  //       break;
  //     case 'home':
  //       this.homeIcon = mouseIn ? 'assets/images/topnav/home-active.png' : 'assets/images/topnav/home.png';
  //       break;
  //     case 'mobile':
  //       this.mobileIcon = mouseIn ? 'assets/images/topnav/mobile-active.png' : 'assets/images/topnav/mobile.png';
  //       break;
  //   }
  // }

  returnZero() {
    return 0;
  }

  navToAff() {
    window.location.href = this.affiliateUrl;
  }

  isUserLoggedIn() {
    return this.authService.isUserLoggedIn;
  };

  get isTokenExpired() {
    if (!this.authService.isTokenExpired()) {
      return false;
    }
    this.store.dispatch(logout());
    localStorage.setItem('redirectToLogin', '1');
    return true;
  }

  private getUserKYC() {
    const userData = localStorage.getItem('user_data');
    this.showKYCGateKeeper = window.location.pathname === '/member/referrals' || window.location.pathname === '/vip' || window.location.pathname === '/referral' ? true : false;
    if (this.showKYCGateKeeper && userData !== null) {
      this.memberKYC = JSON.parse(userData).KYC;
      this.memberGroup = JSON.parse(userData).member_group.name;
    }
  }

  private openDialogBy(componentRef: any, type: string) {
    this.dialog.open(componentRef, {
      width: 'auto',
    });
  }

  private initializeMiniLoginForm() {
    this.formMiniLogin = this.fb.group({
      username: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(320)
        ])
      ],
      password: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(100)
        ])
      ]
    });
  }

  getMenuBar() {
    console.log('final')
    this.menuBarHttpService.getMenuBar().subscribe((res) => {
      this.menuLists = res;

      setTimeout(() => {
        this.setMenuMaxItems();
      }, 100);
    });
  }

  private getFlag() {
    var code = localStorage.getItem('country_code') ? localStorage.getItem('country_code').toUpperCase() : "MY";
    this.userFlag = `/assets/images/language/${code}.png`;
    this.currentLangCode = localStorage.getItem('language_code') == 'ZH' ? '中文' : localStorage.getItem('language_code');
  }

  getCurrentUrl() {
    return this.router.url;
  }

  private getMobileDomain() {
    if (this.hostname.split('.').length > 2) {
      this.hostname = this.hostname.substr(this.hostname.indexOf('.') + 1);
    }
    const userData = localStorage.getItem('user_data');
    const userToken = localStorage.getItem('user_token');

    if (this.hostname.split('.').length > 2) {
      this.hostname = this.hostname.substr(this.hostname.indexOf('.') + 1);
    }

    let countryCode = localStorage.getItem('country_code') === null ? 'MY' : localStorage.getItem('country_code');
    // Full domain
    let fullDomain = (environment.https ? 'https://' : 'http://') + this.availableCountryAndLanguages[this.currentDomain][countryCode]['mobilePreExt'][environment.domainEnv] + this.hostname;

    let crossLoginParam = `?userToken=${userToken}&userData=${userData}`;

    if (localStorage.getItem('user_data') === null) {
      this.mobileDomain = fullDomain;
    } else {
      this.mobileDomain = fullDomain + crossLoginParam;
    }
  }

  getCurrentDomain() {
    let hostname = window.location.hostname;
    let domain = hostname.replace('www.', '');
    return domain;
  }

  private getAffiliateUrl() {
    let affCountryObj = this.affiliateDomain[this.currentCountryCode];
    let targetDomain = this.getCurrentDomain();
    this.affiliateUrl = (environment.https ? 'https://' : 'http://') + affCountryObj.desktopPreExt[environment.domainEnv] + (affCountryObj[environment.domainEnv].find(domain => domain == targetDomain) ? targetDomain : affCountryObj[environment.domainEnv][0]) + `?lang=${this.currentCountryCode}_${localStorage.getItem('language_code')}`;
  }

  private festiveStarted(startDate: string) {
    return moment(this.currentDateUTC).isSameOrAfter(startDate + ' 00:00:00');
  }

  private festiveEnd(endDate: string) {
    return moment(this.currentDateUTC).isAfter(endDate + ' 23:59:59');
  }

  private memberWebsocket(memberAccountId) {
    this.websocketService
      .getChannel("member-channel." + memberAccountId)
      .listen(`.MemberEvent`, (data) => {
        const cta = data.CTA;

        if (cta) {
          // do not show deposit dialog if pop up dialog available
          if (cta == 'deposit' && this.popup_ids.length > 0) {
            return;
          }

          this.websocketService.ctaNotification({
            ...data,
            buttonConfirm: cta,
            buttonCancel: "No, Thanks",
          });
        } else {
          this.messages$.next([data.title]);
        }
      });
  }

  private vipEligibilityWebsocket(memberAccountId) {
    this.websocketService
      .getChannel("vip-eligibility-channel." + memberAccountId)
      .listen(`.VIPEligibilityEvent`, (data) => {
        const cta = data.CTA;

        if (data.CTA) {
          this.websocketService.ctaNotification({
            ...data,
            buttonConfirm: cta,
            buttonCancel: "No, Thanks",
          });
        } else {
          this.messages$.next([data.title]);
        }
      });
  }

  private updateMemberInfoWebsocket(memberAccountId) {
    this.websocketService
      .getChannel("update-member-info-channel." + memberAccountId)
      .listen(`.UpdateMemberInfoEvent`, (data) => {
        const member = data.member;
        this.portalMemberProfile.updateLocalUserData(member);
        localStorage.setItem('verified_number', member.phone_validated);
      });
  }

  private updateMemberEventClickWebsocket(memberAccountId) {
    this.websocketService
      .getChannel("update-member-event-click-channel." + memberAccountId)
      .listen(`.UpdateMemberEventClickEvent`, (data) => {
        this.isPredictionTabClicked = data.clicked;
        if (data.clicked == 0) {
          data.events.forEach(id => this.eventEmitterService.eventListStatusUpdate.emit(id));
          this.eventEmitterService.eventListStatusLabelUpdate.emit(data.statuses);
        }
      });
  }

  private createEventSendNotificationWebsocket() {
    this.websocketService
      .getChannel('create-event-send-notification-channel')
      .listen(`.CreateEventSendNotificationEvent`, (data) => {
        this.isPredictionTabClicked = data.clicked;
      });
  }

  private getAltDomain() {
    let hostname = (window.location.hostname).replace(/^([w]{3}.)(?=[a-z0-9]+)/, '');
    // E.g: localhost.com
    // Get localhost.com
    if (hostname.split('.').length > 2) {
      hostname = hostname.substr(hostname.indexOf('.') + 1);
    }

    // First Alternative
    if (
      JSON.stringify(this.availableCountryAndLanguages[environment.altDomainName]).includes('"' + hostname + '"') ||
      environment.altDomainName.split('|').includes(hostname)
    ) {
      return environment.altDomainName;
    }
    // Second Alternative
    else if (
      JSON.stringify(this.availableCountryAndLanguages[environment.altDomainNameTwo]).includes('"' + hostname + '"') ||
      environment.altDomainNameTwo.split('|').includes(hostname)
    ) {
      return environment.altDomainNameTwo;
    }
  }

  getRouterLink(link: string): any[] {

    if (!link) return;

    // There might will be possible 3 type of link will store. (Internal URL, Internal URL with domain full path or External site URL)
    // /casino, http://localhost:4200/casino, https://www.mcbaffiliate.com/
    // So this function is used to handle these URL see which menu bar should set to Active
    if (link.startsWith('http') || link.startsWith('//')) {
      const url = new URL(link);
      const targetHostname = (window.location.hostname).replace(/^([w]{3}.)(?=[a-z0-9]+)/, ''); // Change this to your own hostname
      const isSameHostname = url.hostname === targetHostname;

      if (isSameHostname) {
        return [url.pathname];
      }
    }

    return [link];
  }

  navigateTo(link: string): void {
    if (!link) return;
    if (link == '/affiliate') {
      this.navToAff();
    } else if (link.startsWith('/external?link=')) {
      window.location.href = link.replace('/external?link=', '');
    } else {
      this.router.navigateByUrl(link);
    }
  }

  toggleMoreDropdown(clickOutside = false) {
    const dropdown = this.document.getElementById('more-dropdown');
    const arrow = this.document.getElementById('more-down-arrow');
    const predictionNotification = this.document.getElementById('prediction-notification-more');

    if (clickOutside) {
      dropdown.classList.add('d-none');
      arrow.classList.remove('rotate-180');
      predictionNotification?.classList.remove('d-none');
    } else {
      if (dropdown.classList.contains('d-none')) {
        dropdown.classList.remove('d-none');
        arrow.classList.add('rotate-180');
        predictionNotification.classList.add('d-none');
      } else {
        dropdown.classList.add('d-none');
        arrow.classList.remove('rotate-180');
        predictionNotification.classList.remove('d-none');
      }
    }
  }

  setMenuMaxItems() {
    const width = this.document.getElementById('navigation-menu').offsetWidth;

    if (width >= 1300) {
      this.menuMaxItems = 9;
    } else if (width >= 1000) {
      this.menuMaxItems = 7;
    } else if (width >= 700) {
      this.menuMaxItems = 5;
    } else {
      this.menuMaxItems = 3;
    }
    this.predictionMenuIsHide();
  }

  predictionMenuIsHide() {
    var hiddenMenu = [];
    for (let i = this.menuMaxItems; i < this.menuLists.length; i++) {
      hiddenMenu.push(this.menuLists[i]);
    }
    this.predictionMenuHidden = (hiddenMenu.find(x => x.link == "/prediction") ? true : false);

    var newMenu  = [];
    for (let i = this.menuMaxItems; i < this.menuLists.length; i++) {
      newMenu.push(this.menuLists[i]);
    }
    this.newMenuHidden = (newMenu.find(x => x.new == 1) ? true : false);
  }

  getRejectedVerification(emitter: boolean) {
    this.bankAccountHttpService.getverificationDetails().subscribe(res => {
      if (res.length > 0) {
        localStorage.setItem('rejected_and_pending_verification', 'true');
        this.verificationRejectedPending = true;
      } else {
        localStorage.setItem('rejected_and_pending_verification', 'false');
        this.verificationRejectedPending = false;
      } 

      if (emitter == true) {
        this.eventEmitterService.onVerificationStorageEmitter();
      }
    });
  }

  checkMemberGroupLevel() {
    this.memberGroupImage = JSON.parse(localStorage.getItem('user_data')).member_group.image;
    this.memberGroupName = JSON.parse(localStorage.getItem('user_data')).member_group.name;
    this.memberName = JSON.parse(localStorage.getItem('user_data')).username;

    const memberGroup = JSON.parse(localStorage.getItem('user_data')).member_group.code?.toLowerCase();

    var vipNameList = JSON.parse(localStorage.getItem('user_data')).member_group.name_list;
    if(vipNameList.length > 0){
      var vipName = vipNameList.find(x => x.lang_code == JSON.parse(localStorage.getItem('user_data')).lang_code
                                      && x.country_code == JSON.parse(localStorage.getItem('user_data')).country_code);
      
      if(vipName){
        this.memberGroupName = vipName['name'];
      }else if(JSON.parse(localStorage.getItem('user_data')).member_group.name_default){
        this.memberGroupName = JSON.parse(localStorage.getItem('user_data')).member_group.name_default;
      } 
      
    }else{
      if(JSON.parse(localStorage.getItem('user_data')).member_group.name_default){
        this.memberGroupName = JSON.parse(localStorage.getItem('user_data')).member_group.name_default;
      }
    }

    switch (true) {
      case /bronze/i.test(memberGroup):
        this.memberGroupColor = '1';
        break;

      case /silver/i.test(memberGroup):
        this.memberGroupColor = '2';
        break;

      case /gold/i.test(memberGroup):
        this.memberGroupColor = '3';
        break;

      case /platinum/i.test(memberGroup):
        this.memberGroupColor = '4';
        break;

      case /diamond/i.test(memberGroup):
        this.memberGroupColor = '5';
        break;

      default:
        this.memberGroupColor = '0';
        break;
    }
    
    this.cdr.detectChanges();
  }
}
