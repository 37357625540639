import { EventEmitterService } from '@core/services/event-emitter.service';
import { Component, OnInit} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { map, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { CountDownDetailsComponent } from '@shared/count-down-details/count-down-details.component';
import { GameListHttpService } from '@core/services/game-list-http.service';

@Component({
  selector: 'app-jackpot-slider',
  templateUrl: './jackpot-slider.component.html',
  styleUrls: ['./jackpot-slider.component.scss'],
})
export class JackpotSliderComponent implements OnInit {

  odometerConfig = {
    animation: 'slide',
    format: '(,ddd).dd',
    theme: 'default',
    auto: true,
  }

  availableProvider = JSON.parse(localStorage.getItem('available_providers'));
  SGisActive = false; 

  customOptionsJacpot: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 1000,
    autoplaySpeed: 1000,
    autoplay: true,
    items: 1,
    nav: false,
    animateOut: 'animate__animated animate__slideOutDown',
  };

  gameCodeByJacpot = {
    MGC:  'S-LB01',
    FUFU: 'S-FO01',
    ADV:  'S-PO02',
    GJ:   'S-DG03',
    CJ:   'S-EG02',
  };

  gameCodeByJacpotPostion = [
    'MGC', // 1
    'FUFU', // 2
    'ADV', // 3
    'GJ', // 4
    'CJ' // 5
  ];

  jackpot = [];
  gameList  = {};

  constructor(
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private gameCategoryHttpService: GameCategoryHttpService,
    public dialog: MatDialog,
    private gameListHttpService: GameListHttpService
  ) { 

  }

  ngOnInit() {
    if(this.availableProvider && this.availableProvider['SL'].find(x => x.game_provider_code == 'SG')) this.SGisActive = true;
    this.getGameList();
    //for testing number update
    // var plus = 0;
    this.eventEmitterService.jackpotDataEventEmitter.subscribe(data => {

      if(!this.availableProvider) {
        this.availableProvider = JSON.parse(localStorage.getItem('available_providers'));
        this.SGisActive = true;
      }

      if(this.jackpot.length == 0) {
        this.jackpot = data['jackpot']['data'];
      }else{
        // update only amount, instead array due to the slider will re-render problem
        if(this.jackpot.length > 0 && data['jackpot']['data'].length  > 0){
          let jackpotlist = this.jackpot;
          Object.keys(jackpotlist).forEach(function (key) {
            //for testing number update
            // plus = plus + 5;
            // data['jackpot']['data'][key]['amount']['MYR'] = data['jackpot']['data'][key]['amount']['MYR'] + plus;  

            // only amount different only will update
            if( JSON.stringify(data['jackpot']['data'][key]['amount']) !== JSON.stringify(jackpotlist[key]['amount']) ){
              jackpotlist[key]['amount'] = data['jackpot']['data'][key]['amount'];
            }
          });
          this.jackpot = jackpotlist;
        }
      }
    });

  }

  getOffset(el: any) {
    el = el.getBoundingClientRect();
    return {
      left: el.left + window.scrollX,
      top: el.top + window.scrollY,
      bottom: el.top + window.scrollY
    };
  }

  scroll() {
    const scrollTo = document.getElementById('content');
    const topHight = this.getOffset(scrollTo).top;
    window.scrollTo(0, topHight);
  }


  onLaunchGame(data: string) {
    var gameCode = this.gameList[this.gameCodeByJacpot[data]]['code'];
    data = 'Launch|SG||'+gameCode;
    // E.g data : Launch|game_provider_code|subCategoryCode|game_code, Launch|EZ|roulette|481000
    let datas = data.split('|');
    this.gameCategoryHttpService.getGames('SL').pipe(
      map(res => res.find(x => x.game_provider_code === datas[1]))
    ).subscribe((gameProvider: any) => {
      console.log(gameProvider);
      // To update the params on the URL
      const dateEnd = Date.parse(gameProvider.maintain_end);
      const dateNow = new Date().getTime();

      if (localStorage.getItem('user_data') === null) {
        this.dialog.open(LoginModalComponent, { width: 'auto' });
      }
      else if (gameProvider.maintenance === 1 && dateEnd > dateNow) {
        this.dialog.open(CountDownDetailsComponent, {
          width: 'auto',
          data: {
            dateEnd: gameProvider.maintain_end,
            name: gameProvider.game_provider_name,
            time: gameProvider.maintain_time_left,
          }
        });
      }
      //maintenance with no end date
      else if (gameProvider.maintenance === 1 && gameProvider.maintain_end === '') {
        this.dialog.open(CountDownDetailsComponent, {
          width: 'auto',
          data: {
            name: gameProvider.game_provider_name,
            time: gameProvider.maintain_time_left,
          }
        });
      } else {
        localStorage.setItem('gameProviderCode', gameProvider.game_provider_code);
        localStorage.setItem('gameCategory', 'SL');
        localStorage.setItem('gameProviderId', gameProvider.game_provider_id);
        datas[2] !== undefined ? localStorage.setItem('subCategoryCode', datas[2]) : localStorage.removeItem('subCategoryCode');
        datas[3] !== undefined ? localStorage.setItem('gameCode', datas[3]) : localStorage.removeItem('gameCode');
        localStorage.setItem('lobbyUrl', location.href);

        this.router.navigate([]).then(() => { window.open('/launch', '_blank'); });
      }
    })

  }

  onGetCurrencyCode() {
    let currency_code = 'MYR'; //default MYR

    if (localStorage.getItem('country_code')?.toUpperCase() == 'SG') {
      currency_code = 'SGD';
    } else if (localStorage.getItem('country_code')?.toUpperCase() == 'ID') {
      currency_code = 'IDR';
    }
    return currency_code;
  }

  transferAmount(data){
    let amount = +data.toFixed(2);

    //Workaround to deal with floating point arithmetic issue
    if (Math.round(amount * 100) % 10 == 0) {
      amount = amount + 0.01
    }

    return amount;
  }

  getGameList() {
      var games = {};
      // hard code to get Spade game,slot gamelist
      this.gameListHttpService.getGamesBy('SG', 'SL').pipe(
        tap(res => {
          Object.keys(res).forEach(function (key) {
            if(res[key]['status'] == "ACTIVE") games[res[key]['code']]  = res[key];
          });
          this.gameList = games;
        })
      ).subscribe();
  }
}
