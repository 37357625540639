import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UploadHttpService } from '@core/services/upload-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { catchError, tap, map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { BankAccountHttpService } from '@core/services/bank-account-http.service';
import { of, throwError } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { FileCompressionPipe } from 'app/pipes/file-compression.pipe';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-ewallet-verification-modal',
  templateUrl: './ewallet-verification-modal.component.html',
  styleUrls: ['./ewallet-verification-modal.component.scss']
})
export class EwalletVerificationModalComponent implements OnInit {
  svg: any = svgIconList;

  form: FormGroup;

  uploadInProgress = new Array(2).fill(false);
  fileName: string;
  verificationTitle: string;
  verificationContent: string;

  max_receipts = 10;
  uploaded_images = [];
  uploaded_images_form = [];
  verificationField = [];
  disableButton = true;
  messages$ = this.bankAccountHttpService.messages$;
  isSuccess = this.bankAccountHttpService.isSuccess;
  isCompressed = false;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      reupload: boolean,
      bankAccountId: number,
      reason: string,
      account_name: string,
      account_number: number,
      bank_id: number,
      bank_type: number,
      verificationSetting: any,
      bankVerificationStatus: number
    },
    public dialogRef: MatDialogRef<EwalletVerificationModalComponent>,
    private uploadHttpService: UploadHttpService,
    private dropdownHttpService: DropdownHttpService,
    private sanitizer: DomSanitizer,
    private bankAccountHttpService: BankAccountHttpService,
    public loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    private fileCompression: FileCompressionPipe,
  ) { }

  ngOnInit() {
    if (this.data.reupload) {
      this.verificationField = this.data.verificationSetting.verification_settings_field;
      this.verificationTitle = this.data.verificationSetting.verification_setting_title;
      this.verificationContent = this.data.verificationSetting.verification_setting_content;
    } else {
      this.verificationField = this.data.verificationSetting[0]['verification_settings_field'];
      this.verificationTitle = this.data.verificationSetting[0]['verification_setting_title'];
      this.verificationContent = this.data.verificationSetting[0]['verification_setting_content'];
    }
    this.formInit();
  }

  async onUploadFileMulti(event: any, verificationFieldId: number, fieldIndex: number) {
    this.uploadInProgress[fieldIndex] = true;
    const file: File = event.target.files[0];

    if (file == null) {
      this.uploadInProgress[fieldIndex] = false;
      return;
    }
    var compressedFile: any;
    //Only image & file size greater than 2MB will need to compress
    if (file.type != "application/pdf" && file.size >= 2 * 1048576) {
      // Compress the file
      this.isCompressed = true;
      compressedFile = await this.fileCompression.transform(file);
    }
    
    if (compressedFile || this.isCompressed == false) {
      const formData = new FormData();
      this.fileName = compressedFile ? compressedFile.name : file.name;
      formData.append('files', compressedFile ? compressedFile : file);
      formData.append('type', this.dropdownHttpService.uploadTypes[2]);

      this.uploadHttpService.upload(formData).pipe(
        tap((res: any) => {
          this.uploadInProgress[fieldIndex] = false;
          this.disableButton = false;

          const uploadedImage = {
            path: res[0].indexOf('.pdf') >= 0 ? this.sanitizer.bypassSecurityTrustResourceUrl(res[0]) : res,
            isPdf: res[0].indexOf('.pdf') >= 0 ? true : false,
          };

          // Initialize the array for the field ID if not already present
          if (!this.uploaded_images[verificationFieldId]) {
            this.uploaded_images[verificationFieldId] = [];
            this.uploaded_images_form[verificationFieldId] = [];
          }

          this.uploaded_images[verificationFieldId].push(uploadedImage);
          this.uploaded_images_form[verificationFieldId].push(res[0]);

          this.form.patchValue({
            verification_details: { [verificationFieldId]: this.buildMediaPropertyBy(verificationFieldId)}
          });
          this.form.markAllAsTouched();
        }),
        catchError((error) => {
          this.uploadInProgress[fieldIndex] = false;
          this.disableButton = true;
          throw error;
        })
      ).subscribe();
    } else {
      this.isCompressed = false;
      this.uploadInProgress[fieldIndex] = false;
      if (this.uploaded_images_form[verificationFieldId]?.length == 0) {
        this.disableButton = true;
      } else {
        this.disableButton = false;
      }
    }
    
    event.target.value = null;
  }

  private buildMediaPropertyBy(verificationFieldId: number) {
    let output = {};
    output = { ...output, document_path: this.uploaded_images_form[verificationFieldId] };
    return output;
  }

  onRemoveFileMulti(idx, verificationFieldId: number) {
    if (this.uploaded_images[verificationFieldId]) {
      this.uploaded_images[verificationFieldId].splice(idx, 1);
    }

    this.uploaded_images_form[verificationFieldId].splice(idx, 1); // Assuming this needs modification as well

    this.form.patchValue({
      verification_details: { [verificationFieldId]: this.buildMediaPropertyBy(verificationFieldId)}
    });

    if (this.uploaded_images_form[verificationFieldId]?.length == 0) {
      this.disableButton = true;
    } else {
      this.disableButton = false;
    }

    this.form.markAllAsTouched();
  }

  onCloseDialog(close?: Boolean) {
    this.dialogRef.close(close);
  }

  onSave() {
    if (this.data.reupload == true) {
      this.loadingBar.start()
      const data = {
        ...this.form.value,
      };
      this.bankAccountHttpService.reupload(this.data.bankAccountId, data).pipe(
        tap(res => {
          this.loadingBar.complete();
          this.eventEmitterService.onUpdateVerificationEmitter();
          this.eventEmitterService.onUpdateBankList();
          this.onCloseDialog();
        }),
        catchError(err => {
          this.loadingBar.complete();
          throw err;
        })
      ).subscribe();
    } else {
      const data = {
        ...this.form.value,
        account_name: this.data.account_name,
        account_number: this.data.account_number,
        bank_id: this.data.bank_id,
        bank_type: this.data.bank_type,
        bank_name: null,
        icon_filename: null,
        id: null,
      };
      
      Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
      
      of(data).pipe(
        tap((data) => {
          this.loadingBar.start();
          this.bankAccountHttpService.addBank(data).pipe(
            tap(res => {
              this.loadingBar.complete();
              this.eventEmitterService.onUpdateBankList();
              this.onCloseDialog(true);
            }),
            catchError(err => {
              this.loadingBar.complete();
              return throwError(err);
            })
          ).subscribe();
        }),
      ).subscribe();
    }
  }

  private formInit() {
      const buildContents = () => {
        let fields = {};
        this.verificationField.forEach((element: any) => {
          const subFields = new FormGroup({
            verification_setting_field_id: new FormControl(element.verification_setting_field_id),
            document_path: new FormControl(null, element.type_name == 'Upload File' ? Validators.required : []),
            verification_text: new FormControl(null, element.type_name == 'Textbox' ? Validators.required : [])
          });
          fields = { ...fields, [element.verification_setting_field_id]: subFields };
        });

        return fields;
      };

      this.form = new FormGroup({
        verification_details: new FormGroup(buildContents()),
      });
  }
}
