import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable, concat, defer, interval, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebsocketService } from './websocket.service';
import { EventEmitterService } from '@core/services/event-emitter.service';

@Injectable({
  providedIn: 'root'
})
export class JackpotService {

  private jackpotAmount: number;

  constructor(
    private datePipe: DatePipe,
    private websocketService: WebsocketService,
    private eventEmitterService: EventEmitterService,
  ) {
    this.generateRandomStartingValue();
    this.generateJackpotAmountFromStartingValue();
    this.updateJackpotAmountPeriodically();
  }

  private generateRandomStartingValue() {
    const currentDate = new Date();
    const currentWeek = parseInt(this.datePipe.transform(currentDate, 'w'));

    // Hardcode 'random' amount for everyweek in 1 year (total 52 weeks in 1 year)
    const jackpotAmountsArray = [3039022, 3070115, 2943907, 2965255, 2895560, 2925368, 2995968, 2905974, 2979379, 2897652, 2934815, 2856583, 2914023,
      3053372, 2877787, 3016458, 3052937, 2945473, 2978091, 2859854, 3063253, 3075419, 2989371, 2950632, 2934497, 3036684,
      3132584, 2899799, 2917206, 2894071, 3032306, 2918107, 3080354, 2882779, 2923619, 2991611, 3024116, 3075900, 3010593,
      3069725, 3034945, 3095136, 3075821, 3089304, 3131719, 2881402, 2868954, 2856443, 3088741, 3148374, 3097674, 3102963]

    this.jackpotAmount = jackpotAmountsArray[currentWeek - 1];
  }

  //generate the current jackpot amount from starting value , based on how many seconds passed since last sunday 12am
  private generateJackpotAmountFromStartingValue() {

    const now = new Date();
    const lastSunday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - now.getUTCDay(), 0, 0, 0));

    const secondsSinceLastSunday = Math.floor((now.getTime() - lastSunday.getTime()) / 1000);
    this.jackpotAmount = this.jackpotAmount + (secondsSinceLastSunday * 7.5);
  }

  private updateJackpotAmountPeriodically(): void {
    interval(1000)
      .pipe(
        map(() => this.jackpotAmount + (Math.random() * 5 + 5)) // Update jackpot amount with random value between 5 and 10
      )
      .subscribe(newAmount => {
        this.jackpotAmount = newAmount;
      });
  }

  public getJackpotAmount(): Observable<number> {
    return concat(
      defer(() => of(this.transformAmount(this.jackpotAmount))),
      interval(5000).pipe(
        map(() => this.jackpotAmount),
        map(amount => this.transformAmount(amount)))
    );
  }

  //The ng2-odometer will not display number ending with 0 in the last decimal place in 2 decimal places, so we add 0.01 to make it always display 2 decimal places. 
  //For example, if jackpot amount is 3,000,000.10 , ng2-odometer will display 3,000,000.1 , add 0.01 to display as 3,000,000.11
  //if jackpot amount is 3,000,000.56 ,it will display as  3,000,000.56
  //if jackpot amount is 3,000,010 ,ng2-odometer will display 3,000,010, add 0.01 to display as 3,000,010.01
  private transformAmount(jackpotAmount: number): number {

    let amount = +jackpotAmount.toFixed(2);

    //Workaround to deal with floating point arithmetic issue
    if (Math.round(amount * 100) % 10 == 0) {
      amount = amount + 0.01
    }

    return amount;
  }

  jackpotWebsocket() {
    this.websocketService
      .getChannel('jackpot-prompt-channel')
      .listen(`.jackpotPromptEvent`, (data) => {
        this.eventEmitterService.onJackpotEventEmitter(data);
      });
  }

}
