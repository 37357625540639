// Angular
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from '@core/services/seo.service';
import Swal, { SweetAlertCustomClass } from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { BankMaintenanceHourService } from '@core/services/bank-maintenance-hour.service';
import { RequestReceipt } from '@core/models/request-receipt.model';
import { RequestReceiptHttpService } from '@core/services/request-receipt-http.service';
import { TwoStepDepositService } from '@views/modules/member/services/two-step-deposit.service';
import { TwoStepDepositDataService } from '@views/modules/member/services/two-step-deposit-data.service';
import { EventEmitterService } from '@core/services/event-emitter.service';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {

  @ViewChild('bankMaintenanceHourTemplate') bankMaintenanceHourTemplate: ElementRef;
  @ViewChild('bankMaintenanceHourWithdrawTemplate') bankMaintenanceHourWithdrawTemplate: ElementRef;
  countryCode: string;
  tab: string;
  activeTab = this.activatedRoute.snapshot.paramMap.get('tab') ? this.activatedRoute.snapshot.paramMap.get('tab') : 'transfer';
  bankMaintenanceHour:any = [];

  itemsDisplay = false;
  itemsWithdrawDisplay = false;
  completeFirstLoad = true;
  completeSecondLoad = true;

  user_data = JSON.parse(localStorage.getItem('user_data'));
  newRequestIncoming = this.requestReceiptHttpService.newRequestIncoming;
  depositData: RequestReceipt = null;


  dropdown = {
    merchantBankDeposits: this.dropdownHttpService.merchantBankAccountsDeposit,
  };


  constructor(
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
    private translateService: TranslateService,
    private dropdownHttpService: DropdownHttpService,
    private bankMaintenanceHourService: BankMaintenanceHourService,
    private requestReceiptHttpService: RequestReceiptHttpService,
    private twoStepDepositService: TwoStepDepositService,
    private twoStepDepositDataService: TwoStepDepositDataService,
    private eventEmitterService: EventEmitterService,
  ) { }

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('user_data')) !== null) {
      this.countryCode = localStorage.getItem('country_code');
    }
    this.onSetBankMaintenanceHours();
    this.depositData = RequestReceiptHttpService.requestReceipt;
    this.newRequestIncoming.subscribe(() => {
      this.depositData = RequestReceiptHttpService.requestReceipt;
    })
    localStorage.setItem('wallet_tab', this.activeTab);
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: BeforeUnloadEvent) {
    if (!this.twoStepDepositDataService.getLSStepTwoDeposit()) {
      return;
    }

    if (
      this.activeTab === 'deposit' &&
      !confirm()
    ) {
      event.preventDefault();
      event.returnValue = '';
    }
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   document.getElementById('mySidebar').onclick = (args: any): void => {
    //     var tab = window.location.pathname.split('=').pop();
    //     if (tab === 'transfer' || tab === 'deposit' || tab === 'withdraw' || tab === 'history') {
    //       this.onSelectTab(tab);
    //     }
    //   }
    // }, 500);
  }

  ngOnDestroy() {
    // document.getElementById('mySidebar').onclick = null
  }

  async onSelectTab(tab: string) {
    this.eventEmitterService.onGetAnnouncementEmitter();
    const lsStepTwoDeposit = this.twoStepDepositDataService.getLSStepTwoDeposit();

    if (this.activeTab === 'deposit' &&
        lsStepTwoDeposit &&
       !(await this.twoStepDepositService.handleLeavingStepTwoPage(lsStepTwoDeposit)) // if not leaving
    ) {
      return;
    }

    this.activeTab = tab;
    localStorage.setItem('wallet_tab', tab);
    window.history.replaceState({}, '', `/member/wallet;tab=${tab}`);
    this.seoService.execute();
    this.onSetBankMaintenanceHours();
    this.formatBankMaintenanceHour(JSON.parse(localStorage.getItem('bank_maintenance_hour'))['rows'], tab, true);
  }

  onSetBankMaintenanceHours(){
    if(this.completeFirstLoad == true && this.completeSecondLoad == true){
      this.completeFirstLoad = false;
      this.completeSecondLoad = false;
      this.dropdown.merchantBankDeposits.subscribe(res => {
        sessionStorage.setItem('member_merchant_bank', JSON.stringify(res));

        //get bank_id to get bank maintenance hour
        let bank_ids = [];
        JSON.parse(sessionStorage.getItem('member_merchant_bank')).forEach(merchant_banks => {
          if(merchant_banks['bank_id']){
            bank_ids.push(merchant_banks['bank_id']);
          }
        });
        //get maintenance hour
        this.bankMaintenanceHourService.getBankMaintenanceHours(bank_ids).subscribe(res => {
          localStorage.setItem('bank_maintenance_hour', JSON.stringify(res));
          this.bankMaintenanceHour = [];
          this.formatBankMaintenanceHour(JSON.parse(localStorage.getItem('bank_maintenance_hour'))['rows'], this.activeTab, false);

          this.completeSecondLoad = true;
        });
      
        this.completeFirstLoad = true;
      });
    }
  }

  onBankMaintenanceHours(event: any, activeTab: any){
    if (activeTab == 'deposit') {
      const nativeElement = this.bankMaintenanceHourTemplate.nativeElement;
      nativeElement.style.cssText = 'visibility:visible';

      Swal.fire({
        title: this.translateService.instant('Bank Maintenance Hours'),
        html: this.bankMaintenanceHourTemplate.nativeElement,
        confirmButtonText: this.translateService.instant('Close'),
        showCloseButton: true,
        customClass:{
          title: 'bank-maintenance-hour-title',
          container: 'bank-maintenance-hour-container',
        }
      });
    }
    else if (activeTab == 'withdraw') {
      const nativeElement = this.bankMaintenanceHourWithdrawTemplate.nativeElement;
      nativeElement.style.cssText = 'visibility:visible';

      Swal.fire({
        title: this.translateService.instant('Bank Maintenance Hours'),
        html: this.bankMaintenanceHourWithdrawTemplate.nativeElement,
        confirmButtonText: this.translateService.instant('Close'),
        showCloseButton: true,
        customClass:{
          title: 'bank-maintenance-hour-title',
          container: 'bank-maintenance-hour-container',
        }
      });
    }
  }

  // group day together if same maintenance hours
  formatBankMaintenanceHour(bankMaintenanceHour, activeTab, checkDisplay){
    bankMaintenanceHour.forEach(x => {
      let result = {
        bank_name: x.bank_name,
        bank_code: x.bank_code,
        desktop_image: x.desktop_image,
        mobile_image: x.mobile_image,
        enable_all_day: x.enable_all_day,
        all_day: x.all_day,
        other_day: [],
        gmt: x.gmt,
        display: x.display,
        availability_control: x.availability_control
      }

      if(activeTab == 'deposit'){
        if((x.display == 1 || x.display == 3) && this.itemsDisplay == false) {
          this.itemsDisplay = true;
        }
      }
      else if(activeTab == 'withdraw'){
        if((x.display == 2 || x.display == 3) && this.itemsWithdrawDisplay == false) {
          this.itemsWithdrawDisplay = true;
        }
      }

      if(!checkDisplay){
        if(x.enable_all_day){
          this.bankMaintenanceHour.push(result);
        } else {
          let other_day = [];
          if(x.enable_monday){
            other_day.push({day: 1, time: x.monday});
          }
          if(x.enable_tuesday){
            other_day.push({day: 2, time: x.tuesday});
          }
          if(x.enable_wednesday){
            other_day.push({day: 3, time: x.wednesday});
          }
          if(x.enable_thursday){
            other_day.push({day: 4, time: x.thursday});
          }
          if(x.enable_friday){
            other_day.push({day: 5, time: x.friday});
          }
          if(x.enable_saturday){
            other_day.push({day: 6, time: x.saturday});
          }
          if(x.enable_sunday){
            other_day.push({day: 7, time: x.sunday});
          }

          other_day = other_day.sort((a, b)=> a.day - b.day);

          let last;
          let lastTime;
          let time = [];
          let continuousDays = [];
          let continuousDay = [];

          other_day.forEach((x, i)=>{
            if(i == 0){
              continuousDay.push(x.day);
              time.push(x.time);
            } else {
              if(x.day - last == 1 && JSON.stringify(x.time) === JSON.stringify(lastTime)){
                continuousDay.push(x.day);
                if(i == other_day.length - 1) {
                  continuousDays.push(continuousDay);
                  time.push(x.time);
                }
              } else {
                continuousDays.push(continuousDay);
                continuousDay = [x.day];
                time.push(x.time);
              }
            }
            last = x.day;
            lastTime = x.time;
          });

          if(continuousDays.length){
            continuousDays.forEach((continuousDay, i) => {
              if(continuousDay.length > 1){
                let startIndex = other_day.map(x=> x.day).indexOf(continuousDay[0]);
                let length = continuousDay.length;
                other_day.splice(startIndex, length, {startDay: continuousDay[0], endDay: continuousDay[length-1], time: time[i]});            
              }
            });
          }

          result.other_day = other_day;
          this.bankMaintenanceHour.push(result);
        }
      }

    })
  }

  getDay(day){
    switch (day) {
      case 1: return "Mon";
      case 2: return "Tue";
      case 3: return "Wed";
      case 4: return "Thu";
      case 5: return "Fri";
      case 6: return "Sat";
      case 7: return "Sun";
      default: return;
    }
  }

  // fallback generic bank image
  onImgError(event){
    event.target.src ='/assets/images/generic-bank.jpg';
  }

}
