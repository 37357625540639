// Service
import { BankHttpService } from '@core/services/bank-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { MemberDepositWithdrawLastOptionHttpService } from '@core/services/member-deposit-withdraw-last-option-http.service';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import { UploadHttpService } from '@core/services/upload-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DepositHttpService } from '@views/modules/member/services/deposit-http.service';
import { BankMaintenanceHourService } from '@core/services/bank-maintenance-hour.service';
import { CryptoTokenHttpService } from '../../services/crypto-token-http.service';
import { TwoStepDepositService } from '@views/modules/member/services/two-step-deposit.service';
import { TwoStepDepositDataService } from '@views/modules/member/services/two-step-deposit-data.service';
// Angular
import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
// RxJS
import { of, Subscription } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
// Third Party
import * as moment from 'moment';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { QRCodeElementType } from 'angularx-qrcode';
import { FileCompressionPipe } from 'app/pipes/file-compression.pipe';
// Component
import { BankWrapperModalComponent } from '@shared/bank-wrapper-modal/bank-wrapper-modal.component';
import { BankAccountHttpService } from '@core/services/bank-account-http.service';
import { DepositLimit } from '@core/models/deposit-limit.model';
import svgIconList from 'assets/icons.json';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})
export class DepositComponent implements OnInit, OnDestroy {
  svg: any = svgIconList;

  @ViewChild('tooltipName') tooltipName: MatTooltip;
  @ViewChild('tooltipNumber') tooltipNumber: MatTooltip;
  @ViewChild('tooltipAddress') tooltipAddress: MatTooltip;
  @ViewChild(OwlDateTimeInputDirective) datePicker: OwlDateTimeInputDirective<any>;
  @ViewChildren('focusInput') focusInput: QueryList<ElementRef>;
  @ViewChild('myFileInput') myFileInput: ElementRef;

  public initial_state = {
    elementType: "canvas" as QRCodeElementType,
  }

  public data_model = {
    ...this.initial_state,
  }
  GATEWAY_PDXQR = this.twoStepDepositDataService.GATEWAY_PDXQR;
  GATEWAY_PDXVA = this.twoStepDepositDataService.GATEWAY_PDXVA;

  dropdown = {
    merchantBankDeposits: this.dropdownHttpService.merchantBankAccountsDeposit,
    paymentMethods: null,
    otherPayment: null,
    paymentGateway: null
  };

  bankAccount = {
    name: '',
    number: null
  };
  depositFieldType: DepositLimit['deposit_field_type'] = 'input_field';
  defaultAmountButton = [30, 100, 200, 300, 500, 1000];
  amountButton = [...this.defaultAmountButton];
  currencyCode = '$';
  depositLimit: DepositLimit = null;
  form: FormGroup;
  limits: any;
  merchantBankDeposits$ = [];
  messages$ = this.depositHttpService.messages$;
  isSuccess = this.depositHttpService.isSuccess;
  selectedBank = null;
  selectedMethod: any;
  selectedMethodName = null;
  showChannelDropdown = false;
  uploadInProgress: boolean;
  disableButton = false;
  isBankTransfer: boolean;
  countryCode = localStorage.getItem('country_code');
  languageCode = localStorage.getItem('language_code')?.toLowerCase();
  userGmtOffset = null;

  checkValidity = false;
  showAmountButtons = false;
  fileName: string;

  IDRAmount: string;
  showPreloader = false;
  PGBankLoading = false;

  memberMerchantBank = JSON.parse(sessionStorage.getItem('member_merchant_bank'));
  selectedPaymentGateway = {
    code: '', id: null, image_path: '', maintenance_end_time: null, maintenance_start_time: null, name: '', redirect: null
  };
  showMinDeposit = false;
  PGBankDropdown = [];

  bankMaintenanceHour:any = [];
  gmt:any;
  currentTime:any;
  currentDayOfWeek:any;
  currentDate:any;
  yesterdayTime: any;
  yesterday: any;
  yesterdayDate: any;

  merchantBankItemsDisplay = false;
  itemsDisplay = false;
  qrImg: any = null;
  displayBankDropdown = true;
  max_receipts = 10;
  uploaded_receipts = [];
  uploaded_receipts_form = [];

  availableTokens:any;
  filteredTokens = [];
  selectedToken: string;
  selectedNetwork: string;
  networkDropdownList = [];
  selectedCryptoInfo: any;

  // for two-step payment flow
  currentStep = 1;
  twoStepPaymentPGs = this.twoStepDepositDataService.getTwoStepDepositPGs();
  pgQrCodeSrc = '';
  stepTwoDeposit = null;
  pdxvaStepTwoData: {
    bankName: '',
    bankShortCode: '',
    accountNo: ''
  };

  // countdown
  hours = '00';
  minutes = '00';
  seconds = '00';

  isCompressed = false;

  private countdownInterval: any;

  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  public elementType: QRCodeElementType
  constructor(
    private dropdownHttpService: DropdownHttpService,
    private depositHttpService: DepositHttpService,
    private uploadHttpService: UploadHttpService,
    public loadingBar: LoadingBarService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private eventEmitterService: EventEmitterService,
    private dialog: MatDialog,
    private portalMemberProfileHttpService: PortalMemberProfileHttpService,
    private bankHttpService: BankHttpService,
    private depositLastOptionService: MemberDepositWithdrawLastOptionHttpService,
    private bankAccountHttpService: BankAccountHttpService,
    private bankMaintenanceHourService: BankMaintenanceHourService,
    public twoStepDepositService: TwoStepDepositService,
    public twoStepDepositDataService: TwoStepDepositDataService,
    private cryptoTokenHttpService: CryptoTokenHttpService,
    private fileCompression: FileCompressionPipe,
    private translateService: TranslateService,
  ) {
    this.elementType = this.data_model.elementType;
  }

  ngOnInit() {
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    if (user_data?.gmt !== null) {
      this.userGmtOffset = user_data.gmt;
    }

    // Change step to step two if pending callback two-step deposit exists in local storage,
    // then skipping all other initialization used in step 1
    const lsStepTwoDeposit = this.twoStepDepositDataService.getLSStepTwoDeposit();
    if (lsStepTwoDeposit) {
      this.selectedMethodName = this.twoStepDepositDataService.getLSStepOneSelectedMethodName();
      this.selectedPaymentGateway = JSON.parse(this.twoStepDepositDataService.getLSStepOneSelectedPaymentGateway());
      this.stepTwoDeposit = JSON.parse(lsStepTwoDeposit);

      this.goToStepTwoPayment(this.stepTwoDeposit);
      return;
    }

    // Step 1 initialization below
    if (this.countryCode.toUpperCase() === 'TH') {
      this.amountButton = [100, 200, 300, 500, 1000, 2500];
    }
    if (user_data.currency !== null) {
      this.currencyCode = user_data.currency.code;
      this.countryCode = localStorage.getItem('country_code');
    }
    this.formInit();
    if (this.memberMerchantBank === null || this.memberMerchantBank.length == 0) {
      this.showPreloader = true;
      this.dropdown.merchantBankDeposits.subscribe(res => {
        sessionStorage.setItem('member_merchant_bank', JSON.stringify(res));
        this.memberMerchantBank = res;
        this.showPreloader = false;
        Object.keys(res).forEach((key) => {
          this.merchantBankDeposits$.push(res[key]);
        });

        //get bank_id to get bank maintenance hour
        let bank_ids = [];
        JSON.parse(sessionStorage.getItem('member_merchant_bank')).forEach(merchant_banks => {
          if(merchant_banks['bank_id']){
            bank_ids.push(merchant_banks['bank_id']);
          }
        });
        //get maintenance hour
        this.bankMaintenanceHourService.getBankMaintenanceHours(bank_ids).subscribe(res => {
          localStorage.setItem('bank_maintenance_hour', JSON.stringify(res));
          this.formatBankMaintenanceHour(JSON.parse(localStorage.getItem('bank_maintenance_hour'))['rows']);

          //prepare bank list for display (deposit)
          this.memberMerchantBank.forEach((item, index) => {
            this.gmt = JSON.parse(sessionStorage.getItem('member_merchant_bank'))[index]['gmt'];
            this.isBankMaintenance(item, this.gmt);
            if(item['showBank'] == true && this.merchantBankItemsDisplay == false) {
              this.merchantBankItemsDisplay = true;
            }
          });
          sessionStorage.setItem('member_merchant_bank', JSON.stringify(this.memberMerchantBank));
        });
      });
    } else {
      this.memberMerchantBank.forEach((item, index) => {
        this.gmt = JSON.parse(sessionStorage.getItem('member_merchant_bank'))[index]['gmt'];
        this.isBankMaintenance(item, this.gmt);
        if(item['showBank'] == true && this.merchantBankItemsDisplay == false) {
          this.merchantBankItemsDisplay = true;
        }
      });
      sessionStorage.setItem('member_merchant_bank', JSON.stringify(this.memberMerchantBank));

      Object.keys(this.memberMerchantBank).forEach((key) => {
        this.merchantBankDeposits$.push(this.memberMerchantBank[key]);
      });
    }

    this.eventEmitterService.buttonEmitter.subscribe(() => {
      this.disableButton = false;
    });

    this.eventEmitterService.updateMemberMerchantBankSessionEmitter.subscribe(() => {
      this.dropdown.merchantBankDeposits.subscribe(res => {
        sessionStorage.setItem('member_merchant_bank', JSON.stringify(res));
        this.memberMerchantBank = res;
        this.showPreloader = false;
        Object.keys(res).forEach((key) => {
          this.merchantBankDeposits$.push(res[key]);
        });

        //get bank_id to get bank maintenance hour
        let bank_ids = [];
        JSON.parse(sessionStorage.getItem('member_merchant_bank')).forEach(merchant_banks => {
          if(merchant_banks['bank_id']){
            bank_ids.push(merchant_banks['bank_id']);
          }
        });
        //get maintenance hour
        this.bankMaintenanceHourService.getBankMaintenanceHours(bank_ids).subscribe(res => {
          localStorage.setItem('bank_maintenance_hour', JSON.stringify(res));
          this.formatBankMaintenanceHour(JSON.parse(localStorage.getItem('bank_maintenance_hour'))['rows']);

          //prepare bank list for display (deposit)
          this.memberMerchantBank.forEach((item, index) => {
            this.gmt = JSON.parse(sessionStorage.getItem('member_merchant_bank'))[index]['gmt'];
            this.isBankMaintenance(item, this.gmt);
            if(item['showBank'] == true && this.merchantBankItemsDisplay == false) {
              this.merchantBankItemsDisplay = true;
            }
          });
          sessionStorage.setItem('member_merchant_bank', JSON.stringify(this.memberMerchantBank));
        });
      });

      Object.keys(this.memberMerchantBank).forEach((key) => {
        this.merchantBankDeposits$.push(this.memberMerchantBank[key]);
      });
    });

    // added checking for member bank account, so as to avoid bank acc modal prompting again if member has bank details already
    if (sessionStorage.getItem('member_bank_accounts') === null) {
      this.bankAccountHttpService.getAll().subscribe(res => {
        sessionStorage.setItem('member_bank_accounts', JSON.stringify(res))
      });
    }

    //display last option for user
    this.dropdownHttpService.paymentMethods.subscribe((paymentMethods) => {
      this.dropdown.paymentMethods = paymentMethods
      this.depositLastOptionService.getLastOption(1).subscribe((lastOption) => {
        //if last option exist
        if (lastOption) {
          let indexOfLastPaymentMethodOption = paymentMethods.findIndex(item => item.code === lastOption.payment_method_code);
          if (indexOfLastPaymentMethodOption >= 0) {
            this.onSelectPaymentMethod(paymentMethods[indexOfLastPaymentMethodOption], true);
          }

          //bank/online transfer/ewallet
          if (lastOption.payment_method_code === 'BOT1' || lastOption.payment_method_code === 'EWT1') {
            let indexOfLastMerchantBankOption = this.merchantBankDeposits$.findIndex(item => item.id === lastOption.merchant_bank_id)

            if (indexOfLastMerchantBankOption !== -1) {
              this.onSelectBank(this.merchantBankDeposits$[indexOfLastMerchantBankOption], false)
              this.onGetLimit(this.selectedMethod);
            }
          }

          //quickpay
          if (['QP1', 'EW1', 'QRPP', 'QRDN', 'UPI', 'QRIS', 'FPX', 'PNQR', 'QP3', 'BTG', 'PNVA', 'EWG1'].includes(lastOption.payment_method_code)) {
            this.bankHttpService.getPaymentGateway(lastOption.payment_method_code).subscribe((paymentGateways) => {
              this.dropdown.paymentGateway = paymentGateways;

              if (lastOption?.show_channel) {
                this.dropdown.paymentGateway = this.dropdown.paymentGateway.map((pg, idx) => ({
                  ...pg,
                  channel_logo: `channel-${idx + 1}-${this.languageCode}.png`,
                  channel_name: `Channel ${idx + 1}`,
                  channel_name_short: `CH ${idx + 1}`,
                }));
              }

              let indexOfLastPaymentGatewayOption = paymentGateways.findIndex(item => item.code === lastOption.payment_gateway_code);
              if (indexOfLastPaymentGatewayOption !== -1) {
                this.onSelectPaymentGateway(this.dropdown.paymentGateway[indexOfLastPaymentGatewayOption])
              }
            })
          }
        }
      })
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    // this.datePickerSubscription.unsubscribe();

    // clear countdown to avoid timeout dialog showing up after user exited deposit page
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  getCountdownDuration(expiryDatetime) {
    const expiryDate = new Date(expiryDatetime);
    const currentDate = new Date();
    const countdownDuration = Math.floor((expiryDate.getTime() - currentDate.getTime()) / 1000); // Calculate the difference in seconds
    return countdownDuration;
  }

  startCountdown(duration) {
    if (duration < 0) {
      this.twoStepDepositService.showDepositExpiredMsg(this.stepTwoDeposit);
      return;
    }

    let countdown = duration;

    this.countdownInterval = setInterval(() => {
      const hours = Math.floor(countdown / 3600);
      const minutes = Math.floor((countdown % 3600) / 60);
      const seconds = Math.floor(countdown % 60);

      this.hours = this.padNumber(hours);
      this.minutes = this.padNumber(minutes);
      this.seconds = this.padNumber(seconds);

      countdown--;

      if (countdown < 0) {
        clearInterval(this.countdownInterval);
        const stepTwoDeposit = this.twoStepDepositDataService.getLSStepTwoDeposit();

        if (stepTwoDeposit) {
          this.twoStepDepositService.showDepositExpiredMsg(this.stepTwoDeposit);
        }
      }
    }, 1000);
  }

  saveQRCode(src) {
    var link = document.createElement("a");
    link.href = src;
    link.download = "qr_code.png";
    link.click();
  }  

  // group day together if same maintenance hours
  formatBankMaintenanceHour(bankMaintenanceHour){
    bankMaintenanceHour.forEach(x => {
      let result = {
        bank_name: x.bank_name,
        bank_code: x.bank_code,
        desktop_image: x.desktop_image,
        mobile_image: x.mobile_image,
        enable_all_day: x.enable_all_day,
        all_day: x.all_day,
        other_day: [],
        gmt: x.gmt
      }

      if(x.enable_all_day){
        this.bankMaintenanceHour.push(result);
      } else {
        let other_day = [];
        if(x.enable_monday){
          other_day.push({day: 1, time: x.monday});
        }
        if(x.enable_tuesday){
          other_day.push({day: 2, time: x.tuesday});
        }
        if(x.enable_wednesday){
          other_day.push({day: 3, time: x.wednesday});
        }
        if(x.enable_thursday){
          other_day.push({day: 4, time: x.thursday});
        }
        if(x.enable_friday){
          other_day.push({day: 5, time: x.friday});
        }
        if(x.enable_saturday){
          other_day.push({day: 6, time: x.saturday});
        }
        if(x.enable_sunday){
          other_day.push({day: 7, time: x.sunday});
        }

        other_day = other_day.sort((a, b)=> a.day - b.day);

        let last;
        let lastTime;
        let time = [];
        let continuousDays = [];
        let continuousDay = [];

        other_day.forEach((x, i)=>{
          if(i == 0){
            continuousDay.push(x.day);
            time.push(x.time);
          } else {
            if(x.day - last == 1 && JSON.stringify(x.time) === JSON.stringify(lastTime)){
              continuousDay.push(x.day);
              if(i == other_day.length - 1) {
                continuousDays.push(continuousDay);
                time.push(x.time);
              }
            } else {
              continuousDays.push(continuousDay);
              continuousDay = [x.day];
              time.push(x.time);
            }
          }
          last = x.day;
          lastTime = x.time;
        });

        if(continuousDays.length){
          continuousDays.forEach((continuousDay, i) => {
            if(continuousDay.length > 1){
              let startIndex = other_day.map(x=> x.day).indexOf(continuousDay[0]);
              let length = continuousDay.length;
              other_day.splice(startIndex, length, {startDay: continuousDay[0], endDay: continuousDay[length-1], time: time[i]});
            }
          });
        }

        result.other_day = other_day;
        this.bankMaintenanceHour.push(result);
      }

    })
  }

  /*
  onSwalFire() {
    JSON.parse(localStorage.getItem('announcements_pop'))?.map(announcement => {
      if (announcement.location_name === 'deposit' && announcement.seen === 0) {
        this.announcements$.push(announcement.content);
        this.announcementIds.push(announcement.id);
      }
    });
  }

  onUpdateAnnouncement() {
    const announcementPop = [];
    JSON.parse(localStorage.getItem('announcements_pop')).map(announcement => {
      this.announcementIds.map(res => {
        announcementPop.push({
          ...announcement,
          seen: res === announcement.id ? 1 : announcement.seen
        });
      });
      localStorage.setItem('announcements_pop', JSON.stringify(announcementPop));
    });
  }
  */

  onGetLimit(paymentMethod: any, paymentGatewayId?: number, merchantBankId?: number) {
    this.depositLimit = null;

    const path = this.isPaymentGatewayMethod(paymentMethod) && paymentGatewayId !== undefined
        ? `?payment_gateway_id=${paymentGatewayId}&merchant_bank_id=${merchantBankId}`
        : `?merchant_bank_id=${this.selectedBank.id}`;

    this.depositHttpService.getLimit(path)
      .subscribe(res => {
        this.updateAmountButtonAndValidators(res);

        if (!this.isPaymentGatewayMethod(paymentMethod) ) {
          if (this.depositLimit.require_receipt == 0) {
            this.form.controls['receipt_path'].setValidators([]);
          }

          this.form.controls['receipt_path'].updateValueAndValidity();
        }
      });
  }

  updateAmountButtonAndValidators(depositLimitRes: any) {
    this.depositLimit = depositLimitRes;
    this.currencyCode = this.depositLimit.currency_code;
    this.depositFieldType = 'input_field'; // default as input_field, change if needed if shortcut amount > 0

    if (this.depositLimit && this.depositLimit.shortcut_amount.length > 0) {
      this.depositFieldType = this.depositLimit.deposit_field_type;
      this.amountButton = this.depositLimit.shortcut_amount;
    } else {
      const thAmountButton = [100, 200, 300, 500, 1000, 2500];

      this.amountButton = this.countryCode.toUpperCase() === 'TH' ? thAmountButton : [...this.defaultAmountButton];

      if (!['TH', 'ID', 'MY'].includes(this.countryCode.toUpperCase())) {
        console.log(this.depositLimit.min)
        this.amountButton[0] = this.depositLimit.min < 50 ? 30 : 50;
      }
    }

    const minDeposit = this.countryCode.toUpperCase() === 'ID' ? +(this.depositLimit.min * 1000) : +this.depositLimit.min;
    const maxDeposit = this.countryCode.toUpperCase() === 'ID' ? +(this.depositLimit.max * 1000) : +this.depositLimit.max;

    const validators = [
      Validators.required,
      ...(minDeposit !== 0 ? [Validators.min(minDeposit)] : []),
      ...(maxDeposit !== 0 ? [Validators.max(maxDeposit)] : [])
    ];

    this.form.controls['amount'].setValidators(validators);
    this.form.controls['amount'].updateValueAndValidity();
  }

  onCopyText(inputElement: any, type: string) {
    inputElement.select();
    document.execCommand('copy');

    if (type === 'name') {
      this.tooltipName.show();
      setTimeout(() => this.tooltipName.hide(), 2000);
    } else if (type === 'address') {
      this.tooltipAddress.show();
      setTimeout(() => this.tooltipAddress.hide(), 2000);
    } else {
      this.tooltipNumber.show();
      setTimeout(() => this.tooltipNumber.hide(), 2000);
    }

  }

  onSaveQR(parent: any) {
    let parentElement = null

    if (this.elementType === "canvas") {
      // fetches base 64 data from canvas
      parentElement = parent.qrcElement.nativeElement
        .querySelector("canvas")
        .toDataURL("image/png")
    } else if (this.elementType === "img" || this.elementType === "url") {
      // fetches base 64 data from image
      // parentElement contains the base64 encoded image src
      // you might use to store somewhere
      parentElement = parent.qrcElement.nativeElement.querySelector("img").src
    } else {
      alert("Set elementType to 'canvas', 'img' or 'url'.")
    }

    if (parentElement) {
      // converts base 64 encoded image to blobData
      let blobData = this.convertBase64ToBlob(parentElement)
      // saves as image
      const blob = new Blob([blobData], { type: "image/png" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      // name of the file
      link.download = "angularx-qrcode"
      link.click()
    }
  }

  private convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(";base64,")
    // hold the content type
    const imageType = parts[0].split(":")[1]
    // decode base64 string
    const decodedData = window.atob(parts[1])
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length)
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType })
  }
  
  onAmountDropdownChange(amount) {
    this.form.patchValue({
      amount: amount
    });
  }

  async onUploadFileMulti(event:any) {
    this.uploadInProgress = true;
    const file: File = event.target.files[0];
    if (file == null)  {
      this.uploadInProgress = false;
      return;
    }

    var compressedFile: any;
    //Only image & file size greater than 2MB will need to compress
    if (file.type != "application/pdf" && file.size >= 2 * 1048576) {
      // Compress the file
      this.isCompressed = true;
      compressedFile = await this.fileCompression.transform(file);
    }

    if (compressedFile || this.isCompressed == false) {
      const formData = new FormData();
      this.fileName = compressedFile ? compressedFile.name : file.name;
      formData.append('files', compressedFile ? compressedFile : file);
      formData.append('type', this.dropdownHttpService.uploadTypes[2]);
      this.uploadHttpService.upload(formData).pipe(
        tap((res: any) => {
          this.uploadInProgress = false;
          this.uploaded_receipts.push({
            path: res[0].indexOf('.pdf') >= 0 ? this.sanitizer.bypassSecurityTrustResourceUrl(res[0]) : res,
            isPdf: res[0].indexOf('.pdf') >= 0 ? true : false,
          });
          this.uploaded_receipts_form.push(res[0]);
          this.form.patchValue({
            receipt_path: this.uploaded_receipts_form
          });
          this.form.get('receipt_path').markAsDirty();
          this.form.markAllAsTouched();
          this.myFileInput.nativeElement.value = "";
      }),
        catchError((error) => {
          this.uploadInProgress = false;
          this.myFileInput.nativeElement.value = "";
        throw error;
        })
      ).subscribe();
    } else {
      this.isCompressed = false;
      this.uploadInProgress = false;
    }
    event.target.value = null;
  }

  onRemoveFileMulti(idx) {
    this.uploaded_receipts.forEach((value,index)=>{
      if(index==idx) this.uploaded_receipts.splice(index,1);
    });
    this.uploaded_receipts_form.forEach((value,index)=>{
      if(index==idx) this.uploaded_receipts_form.splice(index,1);
    });
    this.form.patchValue({
      receipt_path: this.uploaded_receipts_form
    });
    this.form.get('receipt_path').markAsDirty();
    this.form.markAllAsTouched();
  }

  onConfirm() {
    this.form.patchValue({
      merchant_bank_id: null,
      bank_code: null,
      amount: null,
      receipt_path: null,
      payment_method_code: null,
      payment_gateway_code: null
    });

    this.bankAccount = {
      name: '',
      number: null
    };
    this.onReset();
  }

  onSave() {
    if (sessionStorage.getItem('member_bank_accounts') === "[]" || sessionStorage.getItem('member_bank_accounts') === null) {
      this.openDialogBy(BankWrapperModalComponent, true);
    } else {
      this.checkValidity = true;
      if (this.form.valid) {
        this.loadingBar.start();
        const data = {
          ...this.form.value
        };
        Object.keys(data).forEach((key) => {
          if (data[key] !== '' && data[key] !== null && data[key] !== undefined) {
            data[key] = key === 'datetime'
              ? moment(data[key]).utc().format('YYYY-MM-DD HH:mm:ss')
              : data[key];
          } else {
            delete data[key];
          }
        });
        this.depositHttpService.add(data).subscribe((res) => {
          this.loadingBar.complete();
          this.isSuccess = this.depositHttpService.isSuccess;

          if (this.isSuccess) {
            if (this.isBankTransfer) {
              const tab = 1;
              return this.router.navigate(['/member/history', { tab }]);
            }

            if (!this.isBankTransfer) {
              const paymentGatewayCode = this.selectedPaymentGateway.code;

              // show another prompt for NEXXPAY
              if (paymentGatewayCode === 'NEXXPAY') {
                Swal.fire({
                  html: '<div class="msg-icon">' + this.svg.processingIcon + '</div>' +
                    '<div class="text-center m-t-20">' +
                    '<ul><li class="mt-4 px-4">' + this.translateService.instant('We are currently processing your payment. Please check your transaction history for the latest update.') + '</li></ul>' +
                    '</div>',
                  confirmButtonText: this.translateService.instant('OK'),
                  showCloseButton: true,
                  showDenyButton: false,
                  showConfirmButton: true,
                  reverseButtons: false,
                }).then(result => {
                  if (result.isConfirmed) {
                    this.onConfirm();
                    this.router.navigate(['/member/history']);
                  }
                });
              }

              // PG that have use GET redirect
              const redirectPG = [
                'IP',
                'AEP',
                'AEPTP',
                'YPAY',
                'YPAYQR',
                'OMPFPX',
                'OMPQR',
                'OMPQRPP',
                'OMPQRIS',
                'VTPAY',
                'VTPAYQR',
                'VTPAYVA',
                'FPAYDNQR',
                'FPAYFPX',
                'WINPAY',
                'WINPAYEW',
                'WINPAYQR',
                'SUREPAY',
                'NEXXPAY',
                'DGPAY',
                'DGPAYVA',
                'DGPAYEW',
                'DGPAYQRIS'
              ];
              if (redirectPG.includes(paymentGatewayCode)) {
                this.router.navigate([]).then(() => {
                  window.open(res.data.fundtransfer_url, "_blank");
                });
              } else if (this.twoStepPaymentPGs.includes(paymentGatewayCode)) { // PG that have two-step payment 
                this.stepTwoDeposit = {
                  ...res.data.deposit,
                  formatted_expiry_date: this.formatUTCDate(res.data.deposit.expiry_date),

                  // if possible, for two-step payment in the future may standardize the data needed group under the field "stepTwoData"
                  stepTwoData: {
                    ...res.data.instructions
                  }
                };

                this.twoStepDepositDataService.storeLocalStorageForStepTwo({
                  'selectedMethodName': this.selectedMethodName,
                  'selectedPaymentGateway': this.selectedPaymentGateway,
                  'stepTwoDeposit': this.stepTwoDeposit,
                });
                this.goToStepTwoPayment(this.stepTwoDeposit);
              } else {
                // const params = Object.keys(res.data.params).map(key => key + '=' + res.data.params[key]).join('&');
                // window.open(res.data.fundtransfer_url + '?' + params, '_blank');
                const transactionForm = {
                  params: res.data.params,
                  url: res.data.fundtransfer_url
                };

                localStorage.setItem('transactionForm', JSON.stringify(transactionForm));
                this.router.navigate([]).then(() => { window.open('/processing-transaction', '_blank'); });
              }
            }
          } else {
            this.loadingBar.complete();
          }
          this.checkValidity = false;
        });
      } else {
        if (this.selectedMethod != undefined) {
          this.form.markAllAsTouched();
        }
      }
    }
  }

  goToStepTwoPayment(stepTwoDeposit) {
    this.currentStep = 2;

    if (this.selectedPaymentGateway.code === this.GATEWAY_PDXQR) {
      this.pgQrCodeSrc = "data:image/png;base64," + stepTwoDeposit.stepTwoData?.qrCodeDisplayImage;
    }

    if (this.selectedPaymentGateway.code === this.GATEWAY_PDXVA) {
      this.pdxvaStepTwoData = stepTwoDeposit?.stepTwoData;
    }

    // start timer countdown
    const countdownDuration = this.getCountdownDuration(this.stepTwoDeposit.expiry_date);
    this.startCountdown(countdownDuration);
  }

  onSelectAmount(selectedAmount: number) {
    if (this.countryCode.toUpperCase() === 'ID') {
      this.IDRAmount = selectedAmount.toString();
      this.form.patchValue({ amount: selectedAmount });
    } else {
      this.form.patchValue({ amount: selectedAmount });
    }
  }

  // ONLY IDR WILL TRIGGER THIS FUNCTION
  onInputAmount() {
    this.form.patchValue({ amount: +this.IDRAmount });
  }

  onOtherAmount() {
    this.form.patchValue({ amount: null });
    of(null).pipe(
      delay(0), tap(() => this.focusInput.first.nativeElement.focus()
      )).subscribe();
  }

  onSelectPaymentMethod(method: any, fromLastOption?: boolean) {
    this.onResetCryptoInfo();
    this.onResetCryptoFields();
    this.form.markAsUntouched();
    this.checkValidity = false;
    this.displayBankDropdown = true;
    this.selectedPaymentGateway = {
      code: '', id: null, image_path: '', maintenance_end_time: null, maintenance_start_time: null, name: '', redirect: null
    };

    if (this.countryCode.toUpperCase() === 'ID') {
      this.amountButton = [10, 25, 100, 300, 500, 1000];
    }

    if (this.countryCode.toUpperCase() === 'TH') {
      if (method.code === 'BOT1' || method.code === 'EWT1') {
        this.amountButton = [100, 200, 300, 500, 1000, 2500];
      } else {
        this.amountButton = [500, 1000, 1500, 2000, 2500, 3000];
      }
    }

    if (this.countryCode.toUpperCase() === 'MY') {
      if (method.code === 'BOT1' || method.code === 'EWT1') {
        this.amountButton = [...this.defaultAmountButton];
      } else {
        this.amountButton = [50, 100, 200, 300, 500, 1000];
      }
    }

    this.selectedMethod = method.code;
    this.selectedMethodName = method.name;
    this.showChannelDropdown = method.show_channel;
    this.selectedBank = null;
    if (['QP1', 'EW1', 'QRPP', 'QRDN', 'UPI', 'QRIS', 'FPX', 'PNQR', 'QP3', 'BTG', 'PNVA', 'CRY', 'EWG1'].includes(method.code)) {
      //check if payment gateway already get when retrieve last option to avoid calling api multiple time
      if (!fromLastOption) {
        this.showPreloader = true;
        this.bankHttpService.getPaymentGateway(method.code).subscribe(res => {
          this.dropdown.paymentGateway = res;

          if (method.show_channel) {
            this.dropdown.paymentGateway = this.dropdown.paymentGateway.map((pg, idx) => ({
              ...pg,
              channel_logo: `channel-${idx + 1}-${this.languageCode}.png`,
              channel_name: `Channel ${idx + 1}`,
              channel_name_short: `CH ${idx + 1}`,
            }));
          }

          this.showPreloader = false;
          if(this.dropdown.paymentGateway.length == 1) {
            const paymentGateway = this.dropdown.paymentGateway[0];
            this.onSelectPaymentGateway(paymentGateway);
            this.onGetLimit(paymentGateway.payment_method_code, paymentGateway.id, paymentGateway.merchant_bank_id);
          }
        });
      }
      this.showMinDeposit = false;
    }
    else {
      this.showMinDeposit = true;
    }
    this.form.patchValue({
      merchant_bank_id: null,
      bank_code: null,
      payment_method_code: method.code,
      payment_gateway_code: null,
      amount: null,
      payupiid: null,
    });

    this.form.controls['merchant_bank_id'].setValidators((method.code === 'BOT1' || method.code === 'EWT1') ? [Validators.required] : []);
    this.form.controls['receipt_path'].setValidators((method.code === 'BOT1' || method.code === 'EWT1') ? [Validators.required] : []);
    this.form.controls['payment_gateway_code'].setValidators(method.code === 'QP1' || method.code === 'QRPP' || method.code === 'QRDN' || method.code === 'UPI' || method.code === 'FPX' || method.code === 'QRIS' || method.code === 'QP3' ? [Validators.required] : []);
    this.form.controls['bank_code'].setValidators(
      (method.code === 'QP1' && this.selectedPaymentGateway !== null && ['IP', 'TP', 'FP', 'EZP'].includes(this.selectedPaymentGateway.code)) ||
      (method.code === 'EW1' && this.selectedPaymentGateway !== null && ['TPDNQR'].includes(this.selectedPaymentGateway.code)) ||
      (method.code === 'EWG1' && this.selectedPaymentGateway !== null && ['WINPAYEW'].includes(this.selectedPaymentGateway.code)) ||
      (method.code === 'EWG1' && this.selectedPaymentGateway !== null && ['DGPAYEW'].includes(this.selectedPaymentGateway.code)) ||
      (method.code === 'FPX' && this.selectedPaymentGateway !== null && ['EZPFPX'].includes(this.selectedPaymentGateway.code)) ||
      (method.code === 'QRDN' && this.selectedPaymentGateway !== null && ['EZPQR'].includes(this.selectedPaymentGateway.code)) ||
      (method.code === 'QRPP' && this.selectedPaymentGateway !== null && ['EZPQRPP'].includes(this.selectedPaymentGateway.code)) ||
      (method.code === 'QRIS' && this.selectedPaymentGateway !== null && ['H2PQRIS'].includes(this.selectedPaymentGateway.code)) ||
      (method.code === 'PNQR' && this.selectedPaymentGateway !== null && ['TPDNQR'].includes(this.selectedPaymentGateway.code)) ? [Validators.required] : []);
    this.form.controls['payupiid'].setValidators(method.code === 'UPI' ? [Validators.required] : []);

    this.form.controls['merchant_bank_id'].updateValueAndValidity();
    this.form.controls['receipt_path'].updateValueAndValidity();
    this.form.controls['payment_gateway_code'].updateValueAndValidity();
    this.form.controls['bank_code'].updateValueAndValidity();
    this.form.controls['payupiid'].updateValueAndValidity();
    this.bankAccount = {
      name: null,
      number: null
    };
    if (method.redirect === 0) {
      this.isBankTransfer = true;
    } else {
      this.isBankTransfer = false;
    }

    if(method.code === 'BOT1') {
      this.merchantBankDeposits$ = this.memberMerchantBank.filter(x => x.bank_type == 1);
    }
    else if(method.code === 'EWT1') {
      this.merchantBankDeposits$ = this.memberMerchantBank.filter(x => x.bank_type == 3);
    }

    this.qrImg = null

    if (method.code === 'EWT1' && this.merchantBankDeposits$.length === 1) {
      this.onSelectBank(this.merchantBankDeposits$[0]);
    }
  }

  onSelectBank(bank: any, ifFastOrInstantPay: boolean = false) {
    if(bank.showBank != false) {
      this.selectedBank = bank;

      if(this.selectedMethod === 'EWT1' || this.selectedMethod === 'BOT1') {
        this.qrImg = bank.qr_image;
      }

      if (this.selectedMethod === 'BOT1' || this.selectedMethod === 'EWT1') {
        this.form.patchValue({
          merchant_bank_id: bank.id,
        });
      }
      this.bankAccount = {
        name: bank.account_name,
        number: bank.account_number
      };

      // FastPay || InstantPay || TruePay
      if (ifFastOrInstantPay) {
        this.form.patchValue({
          bank_code: bank.code,
        });
      }
    }
  }

  onSelectToken(event: any) {
    if( event.token == this.selectedToken ) {
      return;
    }

    this.selectedNetwork = '';
    this.onResetCryptoInfo();
    
    this.selectedToken = event.token;
    const matchingTokens = this.availableTokens.filter(token => token.token === event.token);
    this.networkDropdownList = matchingTokens.map(token => ({ id: token.id, token: token.token, network: token.network }));
    if (this.networkDropdownList.length == 1) {
      this.onSelectNetwork(this.networkDropdownList[0].network);
    }
  }

  onSelectNetwork(selectedNetwork) {
    if( selectedNetwork == this.selectedNetwork ) {
      return;
    }

    this.onResetCryptoInfo();
    this.selectedNetwork = selectedNetwork;

    const selectedCrypto = this.availableTokens.filter(token => token.token === this.selectedToken && token.network === this.selectedNetwork);
    if( selectedCrypto.length > 0 ) {
      this.selectedCryptoInfo = {
        'address':  selectedCrypto[0].address || '',
        'estimated_credit': selectedCrypto[0].Estimate_Given_Credit || '',
        'check_status_url': selectedCrypto[0].check_status_url || ''
      };
    }
  }

  onGetCryptoInfo() {
    this.loadingBar.start();
    this.checkValidity = true;
    this.depositHttpService.getCryptoInfo(`?code=${this.selectedPaymentGateway.code}`).subscribe(res => {
      this.loadingBar.complete();
      if( res.success === true && res.data ) {
        this.availableTokens = JSON.parse(JSON.stringify(res.data));
        this.filteredTokens = Array.from(new Set(this.availableTokens.map(token => token.token))).map(token => {
          return this.availableTokens.find(t => t.token === token);
        });
      }
      this.checkValidity = false;
    });
    
  }

  onResetCryptoFields() {
    this.selectedToken = '';
    this.selectedNetwork = '';
  }

  onResetCryptoInfo() {
    this.selectedCryptoInfo = null;
  }

  onCheckStatus() {
    if( !this.selectedCryptoInfo || !this.selectedCryptoInfo.check_status_url ) {
      return;
    }
    window.open(this.selectedCryptoInfo.check_status_url, '_blank');
    window.location.href = '/member/wallet;tab=history';
  }

  onSelectPaymentGateway(item: any) {
    this.selectedPaymentGateway = item;
    this.onGetLimit(this.selectedMethod, item.id, item.merchant_bank_id);
    this.selectedBank = null;
    this.form.patchValue({
      payment_gateway_code: item.code,
      bank_code: null
    });
    const itemCode = item.code === 'EZP' || item.code === 'EZP2' ? 'EZP' : item.code;
    this.dropdown.otherPayment = this.bankHttpService.getPaymentGatewayBank(itemCode) || null;

    // Fast Pay/InstantPay/TruPay
    if (this.hasPaymentGatewayBanks(item.code)) {
      this.PGBankLoading = true;
      this.itemsDisplay = false;

      // currently feature only patch for method QP3
      if (this.selectedMethod == 'QP3') {
        this.displayBankDropdown = false;
      } else {
        this.displayBankDropdown = true;
      }

      this.bankHttpService.getPaymentGatewayBank(item.code).subscribe(res => {
        this.PGBankDropdown = res;
        this.PGBankDropdown.forEach(item => {
          this.gmt = item.gmt;
          this.isBankMaintenance(item, this.gmt);
          if(item['showBank'] == true && this.itemsDisplay == false) {
            this.itemsDisplay = true;
          }
        });

        // pick first item if bank dropdown only one item
        if (this.selectedMethod == 'QP3' || this.selectedPaymentGateway.code == 'VTPAYQR' || this.selectedPaymentGateway.code == 'TPDNQR') {
          this.autoSelectBankDropdown(this.PGBankDropdown);
        }

        this.PGBankLoading = false;
      });
    }

    if (item.code === 'C2P') {
      this.onResetCryptoFields();
      this.onGetCryptoInfo();
    }

    this.form.controls['bank_code'].setValidators(
      item.code === 'BCDM1' ||
      [
        'IP',
        'TP',
        'FP',
        'EZP',
        'EZP2',
        'EZPFPX',
        'EZPQR',
        'EZPQRPP',
        'H2PQRIS',
        'SCPFPX',
        'DGPAY',
        'MTPAY',
        'WINPAY',
        'WINPAYEW',
        'PESSC',
        'SUREPAY',
        'DGPAYVA',
        'DGPAYEW',
      ].includes(item.code) ? [Validators.required] : []);
    this.form.controls['bank_code'].updateValueAndValidity();
    if (item.redirect === 0) {
      this.isBankTransfer = true;
    } else {
      this.isBankTransfer = false;
    }
    if (this.countryCode.toUpperCase() === 'ID') {
      if (item.code === 'H2P') {
        this.amountButton = [50, 300, 400, 500, 800, 1000];
      } else {
        this.amountButton = [...this.defaultAmountButton];
      }
    }
    if (this.selectedPaymentGateway !== null || this.selectedPaymentGateway !== undefined) {
      this.showMinDeposit = true;
    }
  }

  private autoSelectBankDropdown(bank: any) {
    // more than one option display bank as usual
    if (bank && bank.length > 1) {
      this.displayBankDropdown = true;
      return;
    }

    // only one option auto select
    if (bank && bank.length == 1) {
      this.onSelectBank(bank[0], true);
    }

    // hide bank when option only one or none
    this.displayBankDropdown = false;
  }

  onReset() {
    this.selectedBank = null;
    this.selectedMethod = null;
    this.selectedMethodName = null;
    this.depositLimit = null;
    this.selectedPaymentGateway = {
      code: '', id: null, image_path: '', maintenance_end_time: null, maintenance_start_time: null, name: '', redirect: null
    };;
  }

  isPaymentGatewayMethod(selectedMethod: string) {
    return (
      selectedMethod === 'QP1' ||
      selectedMethod === 'QP3' ||
      selectedMethod === 'EW1' ||
      selectedMethod === 'QRPP' ||
      selectedMethod === 'QRDN' ||
      selectedMethod === 'QRIS' ||
      selectedMethod === 'UPI' ||
      selectedMethod === 'FPX' ||
      selectedMethod === 'PNQR' ||
      selectedMethod === 'PNVA' ||
      selectedMethod === 'BTG' ||
      selectedMethod === 'CRY' ||
      selectedMethod === 'EWG1'
    );
  }

  formatUTCDate(date: string) {
    return moment(date).utcOffset(this.userGmtOffset).format('YYYY-MM-DD HH:mm:ss');
  }
  
  showGatewayOptions() {
    return (
      this.displayBankDropdown &&
      this.selectedPaymentGateway !== null &&
      this.hasPaymentGatewayBanks(this.selectedPaymentGateway.code)
    );
  }

  hasPaymentGatewayBanks(code: string) {
    const gatewayCodes = [
      'FP',
      'IP',
      'TP',
      'TPDNQR',
      'EZP',
      'EZP2',
      'EZPFPX',
      'EZPQR',
      'EZPQRPP',
      'H2PQRIS',
      'YPAY',
      'SCPFPX',
      'VTPAY',
      'VTPAYQR',
      'VTPAYVA',
      'FPAYFPX',
      'DGPAY',
      'WINPAY',
      'WINPAYEW',
      'MTPAY',
      'PESSC',
      'SUREPAY',
      'NEXXPAY',
      'DGPAYVA',
      'DGPAYEW'
    ];

    return gatewayCodes.includes(code);
  }

  private openDialogBy(componentRef: any, is_deposit?: boolean) {
    this.dialog.open(componentRef, {
      width: 'auto',
      data: {
        is_deposit: is_deposit
      }
    });
  }

  private formInit() {
    const formReceipValidatorOption = (this.selectedMethod === 'BOT1' || this.selectedMethod === 'EWT1') ? [Validators.required] : [];
    const formMerchantValidatorOption = (this.selectedMethod === 'BOT1' || this.selectedMethod === 'EWT1') ? [Validators.required] : [];
    const formBankCodeValidatorOption = this.selectedPaymentGateway !== null && ['IP', 'TP', 'FP'].includes(this.selectedPaymentGateway.code) ? [Validators.required] : [];
    this.form = new FormGroup({
      merchant_bank_id: new FormControl(null, formMerchantValidatorOption),
      bank_code: new FormControl(null, formBankCodeValidatorOption),
      amount: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      // datetime: new FormControl(null),
      receipt_path: new FormControl(null, formReceipValidatorOption),
      // reference: new FormControl(null),
      payment_method_code: new FormControl(null, [Validators.required]), // TODO: Static value in DB
      payment_gateway_code: new FormControl(null),
      payupiid: new FormControl(null),
    });
  }

  isBankMaintenance(item:any, gmt:any){
    item['showBank'] = true;

    if(gmt != '' && (item['availability_control'] == 1 || item['availability_control'] == 3)){
      let hours_minutes = gmt.split(':');
      let posOrNeg = (hours_minutes[0][0] == '-')? '-': '+'; // get positive or negative
      let hours = hours_minutes[0];
      let minutes = hours_minutes[1];

      // get the user current time
      this.currentTime = moment.utc().add(parseInt(hours), 'hours').add(parseInt(posOrNeg+minutes), 'minutes');
      this.currentTime = moment(this.currentTime.format('HH:mm'), 'HH:mm');
      this.currentDayOfWeek = this.currentTime.clone().locale('en').format('dddd').toLowerCase();
      this.currentDate = this.currentTime.clone().locale('en').format('YYYY-MM-DD').toLowerCase();

      // get the user yesterday time
      this.yesterdayTime = moment.utc().add(parseInt(hours), 'hours').add(parseInt(posOrNeg+minutes), 'minutes');
      this.yesterdayTime = moment(this.yesterdayTime.format('HH:mm'), 'HH:mm').subtract(1, "days");
      this.yesterday = this.yesterdayTime.clone().locale('en').format('dddd').toLowerCase();
      this.yesterdayDate = this.yesterdayTime.clone().locale('en').format('YYYY-MM-DD').toLowerCase();

      // if enable_all_day is on
      if(item['enable_all_day'] == true){
        for(let i = 0; i < item['all_day'].length; i++){
          // get the maintenance hour time (from & to)
          let all_day = item['all_day'][i];

          // since all day, check yesterday overlap case
          // eg: today is 2024-02-02 00:02
          // it compare 2024-02-01 23:00 -> 2024-02-02 06:00
          let yesterday_from = moment(moment.utc(`${this.yesterdayDate} ${all_day.from}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
          let yesterday_to = moment(moment.utc(`${this.yesterdayDate} ${all_day.to}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');

          // get tomorrow date for to if to time is smaller than from time
          if(yesterday_to < yesterday_from){
            yesterday_to = yesterday_to.add(1, 'days');
          }

          console.log(yesterday_from.toString())
          console.log(yesterday_to.toString());

          // check if the current bank is in maintenance
          // check if the current bank is in maintenance
          if(this.currentTime.isBetween(yesterday_from, yesterday_to) || this.currentTime.isSame(yesterday_from) || this.currentTime.isSame(yesterday_to)){
            item['showBank'] = false;
            break;
          }

          // since all day, check today overlap case
          // eg: today is 2024-02-02 00:02
          // it compare 2024-02-02 23:00 -> 2024-02-03 06:00
          let today_from = moment(moment.utc(`${this.currentDate} ${all_day.from}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
          let today_to = moment(moment.utc(`${this.currentDate} ${all_day.to}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');

          // get tomorrow date for to if to time is smaller than from time
          if(today_to < today_from){
            today_to = today_to.add(1, 'days');
          }

          // check if the current bank is in maintenance
          if(this.currentTime.isBetween(today_from, today_to)  || this.currentTime.isSame(today_from) || this.currentTime.isSame(today_to)){
            item['showBank'] = false;
            break;
          }
        }
      }

      // if yesterday checkbox is enabled
      if (item['enable_'+this.yesterday] == true){
        for(let i = 0; i < item[this.yesterday].length; i++){
          // get the maintenance hour time (from & to)
          let dayOfWeek = item[this.yesterday][i];

          let from = moment(moment.utc(`${this.yesterdayDate} ${dayOfWeek.from}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
          let to = moment(moment.utc(`${this.yesterdayDate} ${dayOfWeek.to}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');

          // get tomorrow date for to if to time is smaller than from time
          if(to < from){
            to = to.add(1, 'days');
          }

          // check if the current bank is in maintenance
          if(this.currentTime.isBetween(from, to) || this.currentTime.isSame(from) || this.currentTime.isSame(to)){
            item['showBank'] = false;
            break;
          }
        }
      }

      // if today checkbox is enabled
      if (item['showBank'] == true && item['enable_'+this.currentDayOfWeek] == true){
        for(let i = 0; i < item[this.currentDayOfWeek].length; i++){
          // get the maintenance hour time (from & to)
          let dayOfWeek = item[this.currentDayOfWeek][i];

          let from = moment(moment.utc(`${this.currentDate} ${dayOfWeek.from}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
          let to = moment(moment.utc(`${this.currentDate} ${dayOfWeek.to}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');

          // get tomorrow date for to if to time is smaller than from time
          if(to < from){
            to = to.add(1, 'days');
          }

          // check if the current bank is in maintenance
          if(this.currentTime.isBetween(from, to) || this.currentTime.isSame(from) || this.currentTime.isSame(to)){
            item['showBank'] = false;
            break;
          }
        }
      }
    }
  }

  private padNumber(number) {
    return number.toString().padStart(2, '0');
  }
}
