<div class="modal-box" role="dialog">
  <div class="modal1">
    <div class="marquee-content">
      <button
        title="Close (Esc)"
        type="button"
        class="mfp-close"
        (click)="onCloseDialog()"
      >
        ×
      </button>
      <div class="modal-body">
        <swiper class="swiper-popup" style="min-width: 300px;" [config]="config" *ngIf="data.contents.length > 0">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              *ngFor="let item of data.contents; let i = index"
            >
              <p class="w-100 custom-padding">
                {{ item["content"] }}
              </p>
            </div>
          </div>

          <div class="swiper-pagination-popup"></div>
        </swiper>
      </div>
    </div>
  </div>
</div>
