<ng-container *ngIf="!onDialog">
  <div class="row">
    <!-- Payment Method -->
    <div class="col-md-6 form-group pr-0">
      <label>{{ 'Payment Method' | translate }}: </label>
      <div class="p-r-15">
        <div [ngClass]="{'required-dropdown': (!form.get('bank_type').pristine && !form.get('bank_type').valid) || (checkValidity && form.controls.bank_type.errors)}">
          <angular2-multiselect [data]="availablePaymentMethod" [(ngModel)]="selectedPaymentMethod" [settings]="paymentMethodDropdownSettings" 
            (onSelect)="onSelectPaymentMethod(selectedPaymentMethod[0].id)" (onDeSelect)="OnDeSelectPaymentMethod($event)" (onDeSelectAll)="OnDeSelectPaymentMethod($event)">
          </angular2-multiselect>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!bankFieldVisible" class="col-md-6"></div>
  <form class="row" [formGroup]="form" id="bank-modal-form" name="bank-modal-form">
    <!-- Bank -->
    <div class="col-md-6 form-group pr-0">
      <label>{{ selectedBankType === 'E-Wallet' ? ('E-Wallet' | translate) : ('Bank' | translate) }}: </label>
      <div class="p-r-15">
        <div [ngClass]="{'required-dropdown': (!form.get('bank_id').pristine && !form.get('bank_id').valid) || (checkValidity && form.controls.bank_id.errors)}">
          <angular2-multiselect [data]="bankDropdownList" [(ngModel)]="selectedBank" [settings]="bankDropdownSettings"
            (onSelect)="onSelectBank($event)" (onDeSelect)="OnDeSelectBank($event)"
            (onDeSelectAll)="OnDeSelectBank($event)" [ngModelOptions]="{standalone: true}">
          </angular2-multiselect>
        </div>
      </div>
    </div>
    <div *ngIf="!bankFieldVisible" class="col-md-6"></div>
    <!-- Paynow -->
    <ng-container *ngIf="selectedBankCode === 'PAYNOW' && countryCode === 'SG'">
      <div class="col-md-6 form-group">
        <label for="">{{ 'Type' | translate }}:</label>
        <div class="bank-dropdown options">
          <div class="form-control" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            data-flip="false">
            <ng-container>
              {{ selectedType ? selectedType.name : ('Please Select' | translate) }}
              <img src="/assets/images/down-arrow.png" class="icon-right">
            </ng-container>
          </div>
          <div class="dropdown-menu dropdown-scroll paymentmethod-dropdown" aria-labelledby="dropdownPaymentMethods">
            <div class="selection-list-hr">
              <ng-container *ngFor="let item of payNowType">
                <a class="row al-center" (click)="onSelectType(item)"
                  [ngClass]="{ 'text-blue': selectedType !== undefined && selectedType.name === item.name }">
                  <div class="col-12 dropdown-label">
                    {{ item.name | translate }}
                  </div>
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="selectedType !== undefined">
        <div class="col-md-6 form-group" *ngIf="selectedType.code === 'mobile'">
          <label for="">{{ 'Mobile Number' | translate }}:</label>
          <div class="row">
            <div class="col">
              <input type="text" class="form-control form-control-sm form-input-style m-b-10"
                placeholder="{{ 'Enter Mobile Number' | translate }}" formControlName="mobile_number" />
            </div>
          </div>
        </div>
        <div class="col-md-6 form-group" *ngIf="selectedType.code === 'nric'">
          <label for="">{{ 'NRIC/FIN' | translate }}:</label>
          <div class="row">
            <div class="col">
              <input type="text" class="form-control form-control-sm form-input-style m-b-10"
                placeholder="{{ 'Enter NRIC/FIN' | translate }}" formControlName="nric_fin" />
            </div>
          </div>
        </div>
        <div class="col-md-6 form-group" *ngIf="selectedType.code === 'uen'">
          <label for="">{{ 'Unique Entity Name (UEN)' | translate }}:</label>
          <div class="row">
            <div class="col">
              <input type="text" class="form-control form-control-sm form-input-style m-b-10"
                placeholder="{{ 'Enter UEN' | translate }}" formControlName="unique_entity_name" />
            </div>
          </div>
        </div>
        <div class="col-md-6 form-group" *ngIf="selectedType.code === 'vpa'">
          <label for="">{{ 'Virtual Payment Address (VPA)' | translate }}:</label>
          <div class="row">
            <div class="col">
              <input type="text" class="form-control form-control-sm form-input-style m-b-10"
                placeholder="{{ 'Enter VPA' | translate }}" formControlName="virtual_payment_address" />
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <!-- Not Paynow -->
    <ng-container *ngIf="selectedBankCode && (selectedBankCode !== 'PAYNOW' || countryCode !== 'SG')">
      <div class="col-md-6 form-group" *ngIf="bankFieldVisible === true">
        <label for="">{{ 'Bank Name' | translate }}:</label>
        <div class="row">
          <div class="col">
            <input class="form-control" formControlName="bank_name" type="text"
              placeholder="{{ 'Enter Your Bank Name' | translate }}"
              [ngClass]="{'is-invalid': checkValidity && form.controls.bank_name.errors }" #focusInput>
          </div>
        </div>
      </div>

      <div class="col-md-6 form-group">
        <label for="">{{ 'Account Name' | translate }}:</label>
        <div class="row">
          <div class="col">
            <input *ngIf="userName !== null" class="form-control" type="text" [value]="userName"
              placeholder="{{ 'Enter Your Full Name' | translate }}" readonly>
            <input *ngIf="userName === null" class="form-control" type="text" formControlName="account_name" [ngClass]="{'is-invalid': checkValidity && form.controls.account_name.errors }"
              placeholder="{{ 'Enter Your Full Name' | translate }}" #focusInput>
          </div>
        </div>
      </div>

      <div class="col-md-6 form-group mb-0">
        <label for="">{{ selectedBankType === 'E-Wallet'? ('E-Wallet Number' | translate) : ('Account Number' | translate) }}:</label>
        <div class="row">
          <div class="col">
            <input class="form-control accNumber" type="text" formControlName="account_number" placeholder="{{ selectedBankType === 'E-Wallet'? ('Your E-Wallet Number' | translate) : ('Your Account Number' | translate) }}" [ngClass]="{'is-invalid': checkValidity && form.controls.account_number.errors }" #focusInput>
          </div>
        </div>
        <label *ngIf="selectedBankType === 'E-Wallet' && countryCode != 'ID'" class="text-left fw-bold w-100 text-blue">{{ 'Please do not fill in mobile number' | translate }}</label>
        <!-- <label *ngIf="selectedBankType === 'E-Wallet' && countryCode == 'ID'" class="text-left fw-bold w-100 text-blue">{{ 'Your e-wallet number (Premier) must be same with your registered contact number' | translate }}</label> -->
      </div>
    </ng-container>

    <div class="col-12 text-center">
      <button class="button-blue" [ngClass]="{'disabled': disabledButton}" [disabled]="disabledButton" (click)="onSubmit(this.selectedBank, false)" >{{ 'Save' | translate }}</button>
    </div>
  </form>

  <hr>
  <div class="row" *ngIf="bankAccounts$.length > 0">
    <div class="col-md-12">
      <label for="">{{ 'Bank Details' | translate }}:</label>
    </div>
    <div class="col-md-6 m-b-20" *ngFor="let row of bankAccounts$">
      <div class="bankDetails-input">
        <div class="row m-l-0 m-r-0 bankDetails-container">
          <div *ngIf="row.icon_filename.split('.',2)[0] === 'otherbank'" class="others-overlay-outer">
            <div class="others-overlay-inner">
              <div class="text-bank">
                <span>{{ row.bank_name }}</span></div>
            </div>
          </div>
          <ng-container *ngIf="row.icon_filename.includes('otherbank') || !row.desktop_image; else notOther">
            <img src="assets/integrations/bank/otherbank.jpg" [alt]=" row.bank_name + ' icon'">
          </ng-container>
          <ng-template #notOther>
            <img [src]="row.desktop_image" [alt]=" row.bank_name + ' icon'">
          </ng-template>
          <div class="account-details">
            <p class="text-blue">{{ row.account_number }}<br>
              <span [matTooltip]="row.account_name" matTooltipPosition="above">{{ row.account_name }}</span>
            </p>
          </div>
          <ng-container *ngIf="row.status != null && row.verification_settings_field.length > 0">
            <div class="status-container" [ngClass]="(row.status == 2 || row.status == 4) ? 'rejected-div' : 'verification-div'">
              <ng-container *ngIf="row.status == 0 || row.status == 3">
                <p class="review-text">{{ 'Reviewing' | translate }}<span class="under-review-icon" [innerHTML]="svg.underReviewIcon | safeHtml"></span></p>
              </ng-container>
              <ng-container *ngIf="row.status == 1">
                <p class="approved-text">{{ 'Verified' | translate }}<span class="green-tick-icon" [innerHTML]="svg.greenTickIcon | safeHtml"></span></p>
              </ng-container>
              <ng-container *ngIf="row.status == 2">
                <p class="rejected-text">{{ 'Rejected' | translate }}<span class="alert-icon" [innerHTML]="svg.alertIcon | safeHtml"></span></p>
                <button class="reupload-button" (click)="onSubmit(null, true, row)"><span class="upload-icon" [innerHTML]="svg.uploadDocIcon | safeHtml"></span></button>
              </ng-container>
              <ng-container *ngIf="row.status == 4">
                <p class="unverified-text">{{ 'Unverified' | translate }}<span class="alert-yellow-icon" [innerHTML]="svg.alertIcon | safeHtml"></span></p>
                <button class="reupload-button" (click)="onSubmit(null, true, row)"><span class="upload-icon" [innerHTML]="svg.uploadDocIcon | safeHtml"></span></button>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!--For add bank dialog on withdraw page-->
<ng-container *ngIf="onDialog">
  <div class="col-12 form-group p-0" *ngIf="is_deposit && (memberBankAccounts == null || memberBankAccounts.length == 0)">
    <label>{{ 'Please update your bank account detail before submitting a deposit request' | translate }}</label>
  </div>
  <div class="col-12 form-group p-0" *ngIf="is_transfer && (memberBankAccounts == null || memberBankAccounts.length == 0)">
    <label>{{ 'Please update your bank account detail before submitting a transfer request' | translate }}</label>
  </div>
  <div class="col-12 form-group p-0" *ngIf="is_withdraw && (memberBankAccounts == null || memberBankAccounts.length == 0)">
    <label>{{ 'Please update your bank account detail before submitting a withdraw request' | translate }}</label>
  </div>
  <div class="col-12 form-group p-0">
    <label>{{ 'Payment Method' | translate }}</label>
    <div [ngClass]="{'required-dropdown': (!form.get('bank_type').pristine && !form.get('bank_type').valid) || (checkValidity && form.controls.bank_type.errors)}">
      <angular2-multiselect [data]="availablePaymentMethod" [(ngModel)]="selectedPaymentMethod" [settings]="paymentMethodDropdownSettings" 
        (onSelect)="onSelectPaymentMethod(selectedPaymentMethod[0].id)" (onDeSelect)="OnDeSelectPaymentMethod($event)" (onDeSelectAll)="OnDeSelectPaymentMethod($event)">
      </angular2-multiselect>
    </div>
  </div>
  <div class="col-12 form-group p-0">
    <label>{{ selectedBankType === 'E-Wallet' ? ('E-Wallet' | translate) : ('Bank' | translate) }}</label>
    <div [ngClass]="{'required-dropdown': (!form.get('bank_id').pristine && !form.get('bank_id').valid) || (checkValidity && form.controls.bank_id.errors)}">
      <angular2-multiselect [data]="bankDropdownList" [(ngModel)]="selectedBank" [settings]="bankDropdownDialogSettings"
        (onSelect)="onSelectBank($event)" (onDeSelect)="OnDeSelectBank($event)" (onDeSelectAll)="OnDeSelectBank($event)">
      </angular2-multiselect>
    </div>
  </div>

  <form class="row" [formGroup]="form" id="bank-modal-form" name="bank-modal-form">
    <ng-container *ngIf="selectedBankCode === 'PAYNOW' && countryCode === 'SG'">
      <div class="col-md-12 form-group">
        <label for="">{{ 'Type' | translate }}:</label>
        <div class="bank-dropdown options" style="max-height: 145px;">
          <div class="form-control" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            data-flip="false">
            <ng-container>
              {{ selectedType ? selectedType.name : ('Please Select' | translate) }}
              <img src="/assets/images/down-arrow.png" class="icon-right">
            </ng-container>
          </div>
          <div class="dropdown-menu dropdown-scroll paymentmethod-dropdown" aria-labelledby="dropdownPaymentMethods">
            <div class="selection-list-hr">
              <ng-container *ngFor="let item of payNowType">
                <a class="row al-center" (click)="onSelectType(item)"
                  [ngClass]="{ 'text-blue': selectedType !== undefined && selectedType.name === item.name }">
                  <div class="col-12 dropdown-label">
                    {{ item.name | translate }}
                  </div>
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="selectedType !== undefined">
        <div class="col-12 form-group" *ngIf="selectedType.code === 'mobile'">
          <label for="">{{ 'Mobile Number' | translate }}</label>
          <div class="row">
            <div class="col">
              <input type="text" class="form-control form-control-sm form-input-style m-b-10"
                placeholder="{{ 'Enter Mobile Number' | translate }}" formControlName="mobile_number" />
            </div>
          </div>
        </div>
        <div class="col-12 form-group" *ngIf="selectedType.code === 'nric'">
          <label for="">{{ 'NRIC/FIN' | translate }}</label>
          <div class="row">
            <div class="col">
              <input type="text" class="form-control form-control-sm form-input-style m-b-10"
                placeholder="{{ 'Enter NRIC/FIN' | translate }}" formControlName="nric_fin" />
            </div>
          </div>
        </div>
        <div class="col-12 form-group" *ngIf="selectedType.code === 'uen'">
          <label for="">{{ 'Unique Entity Name (UEN)' | translate }}</label>
          <div class="row">
            <div class="col">
              <input type="text" class="form-control form-control-sm form-input-style m-b-10"
                placeholder="{{ 'Enter UEN' | translate }}" formControlName="unique_entity_name" />
            </div>
          </div>
        </div>
        <div class="col-12 form-group" *ngIf="selectedType.code === 'vpa'">
          <label for="">{{ 'Virtual Payment Address (VPA)' | translate }}</label>
          <div class="row">
            <div class="col">
              <input type="text" class="form-control form-control-sm form-input-style m-b-10"
                placeholder="{{ 'Enter VPA' | translate }}" formControlName="virtual_payment_address" />
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="(selectedBankCode !== 'PAYNOW' && selectedBankCode) || countryCode !== 'SG'">
      <div class="col-12 form-group">
        <label for="">{{ 'Account Name' | translate }}</label>
        <div class="row">
          <div class="col">
            <input *ngIf="userName !== null" class="form-control" type="text" [value]="userName"
              placeholder="{{ 'Enter Your Full Name' | translate }}" readonly>
            <input *ngIf="userName === null" class="form-control" type="text" formControlName="account_name" [ngClass]="{'is-invalid': checkValidity && form.controls.account_name.errors }"
              placeholder="{{ 'Enter Your Full Name' | translate }}"  #focusInput2>
          </div>
        </div>
      </div>

      <div class="col-12 form-group mb-0">
        <label for="">{{ selectedBankType === 'E-Wallet'? ('E-Wallet Number' | translate) : ('Account Number' | translate) }}</label>
        <div class="row">
          <div class="col">
            <input class="form-control" type="text" formControlName="account_number" placeholder="{{ selectedBankType === 'E-Wallet'? ('Your E-Wallet Number' | translate) : ('Your Account Number' | translate) }}" #focusInput2>
          </div>
        </div>
        <label *ngIf="selectedBankType === 'E-Wallet' && countryCode != 'ID'" class="text-left fw-bold w-100 text-blue">{{ 'Please do not fill in mobile number' | translate }}</label>
        <!-- <label *ngIf="selectedBankType === 'E-Wallet' && countryCode == 'ID'" class="text-left fw-bold w-100 text-blue">{{ 'Your e-wallet number (Premier) must be same with your registered contact number' | translate }}</label> -->
      </div>

      <div class="col-12 form-group" *ngIf="bankFieldVisible === true">
        <label>{{ 'Bank Name' | translate }}</label>
        <div class="row">
          <div class="col">
            <input class="form-control" formControlName="bank_name" type="text" placeholder="{{ 'Enter Your Bank Name' | translate }}">
          </div>
        </div>
      </div>
    </ng-container>

    <div class="col-12 form-group m-t-20">
      <button class="button-blue w-100" [ngClass]="{'disabled': disabledButton}" [disabled]="disabledButton" (click)="onSubmit(this.selectedBank, false)">{{ 'Save' | translate }}</button>
    </div>
  </form>
</ng-container>

<app-swal-alert [success]="isSuccess" [message]="messages$ | async" (confirmed)="onCloseDialog($event)">
</app-swal-alert>
