// Service
import { EventEmitterService } from '@core/services/event-emitter.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthActions } from './auth-action.types';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { DropdownHttpService } from './../../services/dropdown-http.service';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
// Third Party
import Swal from 'sweetalert2';
import { AuthHttpService } from '@core/services/auth-http.service';
import { MatDialog } from '@angular/material/dialog';
import { SiteRouteComponent } from '@shared/site-route/site-route.component';
import { AuthData } from '@core/models/auth-data';
import { RequestReceiptHttpService } from '@core/services/request-receipt-http.service';
import { AnnouncementHttpService } from '@core/services/announcement-http.service';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { EwalletVerificationHttpService } from '@core/services/ewallet-verification-http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthEffects {

  supportedCountry = this.dropdownHttpService.availableCountryAndLanguages;
  hostname = (window.location.hostname).replace(/^([w]{3}.)(?=[a-z0-9]+)/, '');
  currentDomain: string;

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      tap(async action => {
        // Set user information to local storage
        localStorage.setItem('user_data', JSON.stringify(action.user));
        localStorage.setItem('reward_notice', (+action.user.pending_reward === 0 ? 0 : 1).toString());
        localStorage.setItem('user_token', JSON.stringify(action.token));
        localStorage.setItem('country_code', action.user.country_code);
        localStorage.removeItem('announcements_pop');

        // Trigger Smartico integration init event emitter
        const smarticoData = {
          'user_id' : action.user.id,
          'user_lang' : action.user.lang_code
        };
        this.eventEmitterService.onInitSmarticoEventEmitter(smarticoData);

        // Update status
        this.auth.isUserLoggedIn = true;

        // Get current domain
        if (this.hostname.split('.').length > 2) {
          this.hostname = this.hostname.substr(this.hostname.indexOf('.') + 1);
        }
        // First Alternative
        if (
          JSON.stringify(this.supportedCountry[environment.altDomainName]).includes('"' + this.hostname + '"') ||
          environment.altDomainName.split('|').includes(this.hostname)
        ) {
          this.currentDomain = environment.altDomainName;
        }
        // Second Alternative
        else if (
          JSON.stringify(this.supportedCountry[environment.altDomainNameTwo]).includes('"' + this.hostname + '"') ||
          environment.altDomainNameTwo.split('|').includes(this.hostname)
        ) {
          this.currentDomain = environment.altDomainNameTwo;
        }
        // Default
        else {
          this.currentDomain = environment.domainName;
        }

        // Set language code to local storage
        const languages = this.supportedCountry[this.currentDomain][localStorage.getItem('country_code')]['languages'];
        if (!languages.includes(action.user.lang_code.toUpperCase())) {
          localStorage.setItem('language_code', languages[0])
          this.translateService.use((languages[0]).toLocaleLowerCase());
        } else {
          localStorage.setItem('language_code', action.user.lang_code);
          this.translateService.use((action.user.lang_code).toLocaleLowerCase());
        }

        // Trigger flag event emitter
        this.eventEmitterService.onFlagEmitter();
        this.eventEmitterService.onUpdateCountryAndLanguageEmitter();
        this.eventEmitterService.onUpdatePromotionListEmitter();

        // whitelist data is not needed for gamelaunch redirect flow, add condition to prevent too many request error trigger by this api
        // const landing_page_domain = JSON.parse(localStorage.getItem('redirect_site_domain')).find(x => x.type == 2)?.domian;
        if (!window.location.href.includes('launch?redirectDomain')) {
          // Get white list id
          let whitelistId = await this.setWhitelist(action.user);
        }

        // TEMPORARY DISABLE REDIRECT ACTION UNTIL FURTHER NOTICE
        // // whitelist id > 0 will perform redirect action
        // if (action.user.currency.code == 'MYR' && !environment.forceMaintenance && whitelistId > 0 && environment.sitePrefix == 'bp77') {
        //   this.whitelistAnnouncement(whitelistId);s
        // }
        // // Else continue to check member reward notice
        // else {
        // setTimeout(() => {
        //   if (+localStorage.getItem('reward_notice') == 1) {
        //     const rewardNotice = localStorage.getItem('reward_notice');
        //     if (+rewardNotice >= 1 && window.location.pathname !== '/member/reward') {
        //       Swal.fire({
        //         title: '<img class="msg-icon" src="/assets/integrations/rewards/reward-message.png">',
        //         html: `<div class="text-center m-t-20">
        //             <ul class="pending-reward-text">
        //               <li>`+ this.translateService.instant('You have a reward pending to be claimed!') + `</li>
        //             </ul>
        //             </div>`,
        //         confirmButtonText: this.translateService.instant('CLAIM NOW'),
        //         showCloseButton: true,
        //         showCancelButton: true,
        //         cancelButtonText: this.translateService.instant('Later'),
        //         customClass: {
        //           cancelButton: 'later-button',
        //         }
        //       }).then(result => {
        //         if (result.isConfirmed) {
        //           this.router.navigateByUrl('/member/reward');
        //         }
        //       });
        //     }
        //   }
        // }, 500);
        // }

        // Listen to RequestReceiptEvent websocket
        this.requestReceiptHttpService.init(action.user.id);

        // Listen to UpdateRewardPromptEvent websocket
        this.announcementHttpService.init();

        if (window.location.pathname !== '/launch') {
          this.walletHttpService.getMemberBalance().subscribe();
          this.gameCategoryHttpService.getGameCategoryListWithPromo().subscribe();
        }
        
        this.ewalletVerificationHttpService.init(action.user.id);
        this.ewalletVerificationHttpService.updateVerificationSettingsInit();
      }
      )),
    { dispatch: false });

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      tap(() => {
        // Note: Don't clear sessionStorage!
        localStorage.removeItem('user_token');
        localStorage.removeItem('user_data');
        localStorage.removeItem('profile');
        localStorage.removeItem('reward_notice');
        localStorage.removeItem('os-player-id');
        localStorage.removeItem('bank_maintenance_hour');
        localStorage.removeItem('whitelist_id');
        localStorage.removeItem('language_code');
        localStorage.removeItem('country_code');
        localStorage.removeItem('dialog');
        localStorage.removeItem('popup_ids');
        localStorage.removeItem('rejected_and_pending_verification');
        localStorage.removeItem('main_domain_url');
        sessionStorage.removeItem('all_bank');
        sessionStorage.removeItem('game_providers_balance');
        sessionStorage.removeItem('member_bank_accounts');
        sessionStorage.removeItem('member_merchant_bank');
        sessionStorage.removeItem('member_fpbank_code');
        sessionStorage.removeItem('fishingSession');
        sessionStorage.removeItem('crashGameSession');
        sessionStorage.removeItem('slotSession');
        sessionStorage.removeItem('lotterySession');
        sessionStorage.removeItem('tableSession');
        sessionStorage.removeItem('casinoSession');
        sessionStorage.removeItem('promotionSession');

        // Trigger Smartico integration init event emitter
        const smarticoData = {
          'user_id' : null,
          'user_lang' : null
        };
        this.eventEmitterService.onInitSmarticoEventEmitter(smarticoData);

        // Update status
        this.auth.isUserLoggedIn = false;

        // Note: Native redirect to destroy browser!
        // window.location.href = '/';
      })
    ),
    { dispatch: false });

  constructor(
    private router: Router,
    private actions$: Actions,
    private translateService: TranslateService,
    private eventEmitterService: EventEmitterService,
    private dropdownHttpService: DropdownHttpService,
    private auth: AuthHttpService,
    public dialog: MatDialog,
    private requestReceiptHttpService: RequestReceiptHttpService,
    private announcementHttpService: AnnouncementHttpService,
    private gameCategoryHttpService: GameCategoryHttpService,
    private walletHttpService: WalletHttpService,
    private ewalletVerificationHttpService: EwalletVerificationHttpService,
  ) { }

  private setWhitelist(memberAccount: AuthData) {
    return new Promise<number>((resolve, reject) => {
      this.auth.getWhitelist(memberAccount.id).subscribe(res => {
        localStorage.setItem('whitelist_id', res.whitelist_id);
        // TODO: Update brand name 
        // Whitelist data only for BP77
        if ((environment.sitePrefix as string).toUpperCase() == 'BP77') {
          localStorage.setItem('whitelist_data', JSON.stringify(this.compileWhitelistData(memberAccount, res.whitelist_id)));
        }
        resolve(res.whitelist_id);
      });
    })
  }

  private whitelistAnnouncement(whitelistId: number) {
    if (whitelistId && whitelistId > 0 && environment.domainEnv != 'staging') {
      const dialogExist = this.dialog.getDialogById('modal-site-route');
      const sitePrefix = environment.sitePrefix.toUpperCase();
      const hideDialog = localStorage.getItem('hide_save_link_dialog');
      const opendialog = sitePrefix != 'BP9' || (sitePrefix == 'BP9' && hideDialog != '1');
      if (!dialogExist && opendialog) {
        this.dialog.open(SiteRouteComponent, {
          width: '600px',
          height: '400px',
          disableClose: true
        });
      }
    }
  }

  private compileWhitelistData(memberAccount: AuthData, whitelistId: Number) {
    let message = {
      playerId: memberAccount.id,
      username: memberAccount.username,
      countryCode: memberAccount.country_code,
      whitelistGroup: whitelistId,
      domain: window.location.hostname
    };
    return message;
  }

}
