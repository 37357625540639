// Model
import { Pagination } from '@core/models/pagination.model';
// Service
import { EventEmitterService } from '@core/services/event-emitter.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PortalMessageHttpService } from '@core/services/portal-message-http.service';
// RxJS
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
// Enum
import { MessageStatus } from '@core/enums/message-status.enum';
// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl, FormGroup } from '@angular/forms';
// Third Party
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import svgIconList from 'assets/icons.json';

declare var $: any;
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {
  svg: any = svgIconList;

  status = MessageStatus;
  activeTab = 1;
  newMessage = this.portalMessageHttpService.messageSubject;
  message$ = [];
  date = new Date();
  yesterday = new Date((new Date()).valueOf() - 1000 * 60 * 60 * 24);
  messages$ = this.portalMessageHttpService.messages$;
  isSuccess = this.portalMessageHttpService.isSuccess;
  pagination: Pagination;
  pageSize = 15;
  page = 1;
  maxSize = 5;
  currentPage = 1;

  isSearching = false;

  selectedMessageIds = [];
  selectAll: boolean = false;

  searchMessageForm: FormGroup;
  content = [];
  message_id = [];
  readAllIcon = 'assets/images/read-all.png';
  onFocus = false;

  private subscription: Subscription[] = [];

  constructor(
    private portalMessageHttpService: PortalMessageHttpService,
    public loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.eventEmitterService.onReloadMessage();
    this.searchFormInit();
    this.reload();
    this.pagination = this.portalMessageHttpService.pagination;
    this.subscription.push(
      this.newMessage.subscribe((res) => {
        if (res?.content && this.currentPage == 1) {
          setTimeout(() => {
            let data = {
              content: !res.content.includes('<img src') && res.content.includes('http') ? this.onCheckLink(res.content) : res.content,
              created_at: moment(res.created_at).local(true).format('YYYY-MM-DD HH:mm'),
              id: res.id,
              message_bulk_id: res.message_bulk_id,
              read: res.read,
              read_at: res.read_at,
              title: res.title,
              type: res.type,
              checked: false
            }

            var searchMessageData = this.message$ != null ? this.message$.filter(val => +val.id === +data.id) : [];
            if (searchMessageData.length <= 0) {
              if (this.pageSize > this.message$.length) {
                this.message$ = [data].concat(this.message$);
              } else {
                this.message$.splice(-1);
                this.message$ = [data].concat(this.message$);
              }
            }
          }, 500)
        }
      })
    );
  }


  ngOnDestroy() {
    this.subscription.map(sb => sb.unsubscribe());
    // this.onReadAllMessages();
  }

  // @HostListener('window:scroll', [''])
  // onScroll(e: Event) {
  //   if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
  //     if (this.pagination && this.page <= this.pagination.last_page) {
  //       this.page++;
  //       this.onViewPageBy(this.page, this.pageSize);
  //     }
  //   }
  // }

  onSelectTab(tab: number) {
    this.activeTab = tab;
    // this.params = `type=${tab}`;
    this.onViewPageBy(this.currentPage);
  }

  onViewPageBy(page = 1, pageSize?: number, autoReload?: boolean) {
    this.loadingBar.complete();
    this.isSearching = true;
    pageSize = this.pageSize;
    if (autoReload) {
      this.message$ = [];
    }
    this.message$ = [];
    let searchParam = '';
    if (this.searchMessageForm.get('match_subject')?.value !== null && this.searchMessageForm.get('match_subject')?.value !== '') {
      searchParam = `&title=${this.searchMessageForm.get('match_subject')?.value}`;
    }
    this.portalMessageHttpService.getWithQuery(`?page=${page}&perPage=${pageSize}${searchParam}`).pipe(
      tap(res => {
        this.currentPage = page;
        this.pagination = this.portalMessageHttpService.pagination;
        res.map((row: any) => {
          this.message$.push({
            ...row,
            created_at: moment(row.created_at).local(true).format('YYYY-MM-DD HH:mm'),
            content: !row.content.includes('<img src') && row.content.includes('http') ? this.onCheckLink(row.content) : row.content,
            checked: false
          });
        });
        this.isSearching = false;
        this.onViewContent(this.message$[0]);
      })
    ).subscribe();
  }

  onNextPage() {
    if (this.currentPage >= this.pagination.last_page) {
      return;
    }
    this.currentPage++;
    this.onViewPageBy(this.currentPage);
  }

  onPreviousPage() {
    if (this.currentPage <= 1) {
      return;
    }
    this.currentPage--;
    this.onViewPageBy(this.currentPage);
  }

  onCheckLink(content: string) {
    let newContent = content;
    // Use a regular expression to find valid HTTP links in the content, ensure it does not include html tag
    const urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;
    const httpLinks = content.match(urlRegex);

    if (httpLinks) {
      httpLinks.forEach((httplink) => {
        // Remove any trailing &nbsp; characters from the HTTP link
        const cleanedLink = httplink.replace('&nbsp', '');

        // Create an anchor element for the cleaned HTTP link
        const anchorElement = `<a href="${cleanedLink}" target="_blank">${cleanedLink}</a>`;
        
        // Replace the original link with the anchor element
        newContent = newContent.replace(httplink, anchorElement);
      });
    }
    return newContent;
  }

  onSelectMessage(checked: boolean, id: number) {
    if (checked) {
      this.selectedMessageIds.push(id);
    } else {
      this.selectedMessageIds = this.selectedMessageIds.filter(x => x !== id)
    }
  }

  onSelectByGroup(type: 'all' | 'none' | 'read' | 'unread') {
    switch (type) {
      case 'all': {
        this.selectAll = true;
        this.selectedMessageIds = [];
        this.message$.forEach(item => {
          this.selectedMessageIds.push(item.id)
          item.checked = 1;
        });
        break;
      }
      case 'none': {
        this.selectAll = false;
        this.selectedMessageIds = [];
        this.message$.forEach(item => item.checked = 0);
        break;
      }
      case 'read': {
        this.selectAll = true;
        this.selectedMessageIds = [];
        this.message$.forEach(item => {
          if (item.read) {
            this.selectedMessageIds.push(item.id);
            item.checked = 1;
          } else {
            this.selectAll = false;
            item.checked = 0;
          }
        });
        break;
      }
      case 'unread': {
        this.selectAll = true;
        this.selectedMessageIds = [];
        this.message$.forEach(item => {
          if (!item.read) {
            this.selectedMessageIds.push(item.id);
            item.checked = 1;
          } else {
            this.selectAll = false;
            item.checked = 0;
          }
        });
        break;
      }
    }
  }

  markRead() {
    if (this.selectedMessageIds.length > 0) {
      const data = {
        message_id: this.selectedMessageIds,
        read: 1,
        type: 1
      };
      this.portalMessageHttpService.updateRead(data).subscribe(() => {
        this.isSuccess = this.portalMessageHttpService.isSuccess;
        this.message$.forEach(x => {
          x.checked = false;
          if (this.selectedMessageIds.includes(x.id)) {
            x.read = 1;
          }
        });
        this.selectedMessageIds = [];
        this.selectAll = false;
      }
      );
    } else {
      this.onReadAllMessages();
    }
  }

  onConfirm() {
    this.onViewPageBy(this.currentPage);
  }

  onSelectAll(checked: boolean) {
    this.selectedMessageIds = [];
    this.message$.forEach(x => {
      x.checked = checked;
      if (checked) {
        this.selectedMessageIds.push(x.id);
      }
    });
  }

  onViewContent(row: any) {
    this.content = row;
    if (row && row.read != 1) {
      this.message_id.push(row.id);
      const data = {
        message_id: this.message_id,
        read: 1,
        type: 1
      };
      this.portalMessageHttpService.updateRead(data).subscribe(() => {
        row.read = 1;
        this.message_id = [];
      }
      );
    }
  }

  private onReadAllMessages() {
    this.portalMessageHttpService.updateRead({ read_all: 1 }).subscribe(() => {
      this.message$.map((row) => {
        row.read = 1;
      });
    });
  }

  private reload() {
    this.onViewPageBy(this.currentPage);
  }

  onDeleteMessage() {
    if (this.selectedMessageIds.length > 0) {
      let data = `?`;
      this.selectedMessageIds.forEach((id, index) => {
        data += `id[${index}]=${id}&`;
      })
      this.portalMessageHttpService.deleteMessage(data).subscribe(() => {
        this.isSuccess = this.portalMessageHttpService.isSuccess;
        this.message$.forEach(x => {
          x.checked = false;
        });
        this.selectedMessageIds = [];
        this.selectAll = false;
        this.onViewPageBy(1, this.pageSize, true);
      }
      );
    }
  }

  private searchFormInit() {
    this.searchMessageForm = new FormGroup({
      match_subject: new FormControl(null),
    });
    this.searchMessageForm.valueChanges
      .pipe(
        debounceTime(500),
        map(res => res.match_subject),
        distinctUntilChanged()
      )
      .subscribe(res => {
        if (res !== undefined && res !== null) {
          this.page = 1;
          this.onViewPageBy(1, this.pageSize, true);
        }
      });
  }

  onContent(content) {
    return this.sanitizer.bypassSecurityTrustHtml(content.replace(/\&lt;/g, '<').replace(/\&gt;/g, '>'));
  }

}
