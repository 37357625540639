// Angular
import { Injectable, EventEmitter } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  reloadMessageEmitter = new EventEmitter();
  reloadBankListEmitter = new EventEmitter();
  reloadUnreadMessageEmitter = new EventEmitter();
  reloadProfileEmitter = new EventEmitter();
  openPhoneVerificationEmitter = new EventEmitter();
  verificationTimerEmitter = new EventEmitter();
  buttonEmitter = new EventEmitter();
  availableProviderEmitter = new EventEmitter();
  topBarTabEmitter = new EventEmitter();
  flagEmitter = new EventEmitter();
  resetAnnouncementEmitter = new EventEmitter();
  slideAnnouncementEmitter = new EventEmitter();
  swalAnnouncementEmitter = new EventEmitter();
  updateUsernameEmitter = new EventEmitter();
  updateProfileNameEmitter = new EventEmitter();
  selectProviderTabEmitter = new EventEmitter();
  updateFavoriteEmitter = new EventEmitter();
  metaTagDefault = new EventEmitter();
  updateSeoFooterEmitter = new EventEmitter();
  updateContactUsEmitter = new EventEmitter();
  updateCountryAndLanguageEmitter = new EventEmitter();
  updatePromotionListEmitter = new EventEmitter();
  updateProfileErrorCloseAllModalEmitter = new EventEmitter();
  updateMemberMerchantBankSessionEmitter = new EventEmitter();

  bankListVar: Subscription;
  subsVar: Subscription;
  unreadMessageVar: Subscription;
  resetAnnouncementVar: Subscription;
  buttonVar: Subscription;
  availableProviderVar: Subscription;
  topBarTabVar: Subscription;
  slideAnnouncementVar: Subscription;
  swalAnnouncementVar: Subscription;
  updateUsernameVar: Subscription;
  selectProviderTabVar: Subscription;
  updateFavoriteVar: Subscription;
  profile: Subscription;
  contactUsVar: Subscription;

  footerPaddingEmiter = new EventEmitter();

  liveChatWidgetEmitter = new EventEmitter();
  liveChatWidgetVar: Subscription;

  onUpdateSlotsVar: Subscription;
  UpdateSlotsEmitter = new EventEmitter();

  marqueesEmitter = new EventEmitter();
  marqueesEmitterVar: Subscription;

  bannersEmitter = new EventEmitter();
  bannersEmitterVar: Subscription;

  topbarEventClicked = new EventEmitter();
  topbarEventClickedVar: Subscription;

  eventListStatusUpdate = new EventEmitter();
  eventListStatusUpdateVar = new Subscription;

  eventListStatusLabelUpdate = new EventEmitter();
  eventListStatusLabelUpdateVar = new Subscription();
  
  updateRewardBubbleEmitter = new EventEmitter();
  updateverificationEmitter = new EventEmitter();
  updateBankListEmitter = new EventEmitter();
  updateVerificationStorageEmitter = new EventEmitter();
  updateAnnouncementEmitter = new EventEmitter();

  smarticoDataEventEmitter = new EventEmitter();
  updateVIPEmitter = new EventEmitter();
  updateVIPVar: Subscription;

  updateMemberGroupDetailsEmitter = new EventEmitter();

  jackpotDataEventEmitter = new EventEmitter();

  constructor() { }

  onUpdateContactUs() {
    this.updateContactUsEmitter.emit();
  }

  onUpdateSeoFooter(code: any) {
    this.updateSeoFooterEmitter.emit(code);
  }

  onUpdateUsername(username?: any) {
    this.updateUsernameEmitter.emit(username);
  }

  onReloadMessage(type?: any) {
    this.reloadMessageEmitter.emit();
  }

  onReloadBankList() {
    this.reloadBankListEmitter.emit();
  }

  onReloadUreadMessage() {
    this.reloadUnreadMessageEmitter.emit();
  }

  onOpenPhoneVerificationEmitter() {
    this.openPhoneVerificationEmitter.emit();
  }

  onVerificationTimerEmitter() {
    this.verificationTimerEmitter.emit();
  }

  onFlagEmitter() {
    this.flagEmitter.emit();
  }

  onResetAnnouncementEmitter() {
    this.resetAnnouncementEmitter.emit();
  }

  onButtonEmitter() {
    this.buttonEmitter.emit();
  }

  onAvailableProviderEmitter() {
    this.availableProviderEmitter.emit();
  }

  onTopBarTabEmitter() {
    this.topBarTabEmitter.emit();
  }

  onSlideAnnouncementEmitter() {
    this.slideAnnouncementEmitter.emit();
  }

  onSwalAnnouncementEmitter(page: string) {
    this.swalAnnouncementEmitter.emit(page);
  }

  onLiveChatWidget() {
    this.liveChatWidgetEmitter.emit();
  }

  onUpdateProfileName() {
    this.updateProfileNameEmitter.emit();
  }

  onSelectProviderTabEmitter(activeProvider: any) {
    this.selectProviderTabEmitter.emit(activeProvider);
  }

  onUpdateFavorite() {
    this.updateFavoriteEmitter.emit();
  }

  onUpdateSlots() {
    this.UpdateSlotsEmitter.emit();
  }

  onReloadProfile() {
    this.reloadProfileEmitter.emit();
  }

  onMarqueesEmitter() {
    this.marqueesEmitter.emit();
  }

  onBannersEmitter() {
    this.bannersEmitter.emit();
  }

  onUpdateCountryAndLanguageEmitter(){
    this.updateCountryAndLanguageEmitter.emit()
  }

  onUpdateRewardBubble(){
    this.updateRewardBubbleEmitter.emit();
  }

  onUpdatePromotionListEmitter() {
    this.updatePromotionListEmitter.emit();
  }

  onUpdateProfileErrorCloseAllModalEmitter() {
    this.updateProfileErrorCloseAllModalEmitter.emit();
  }

  onUpdateMemberMerchantBankSessionEmitter() {
    this.updateMemberMerchantBankSessionEmitter.emit();
  }

  onUpdateVerificationEmitter() {
    this.updateverificationEmitter.emit();
  }

  onUpdateBankList() {
    this.updateBankListEmitter.emit();
  }

  onVerificationStorageEmitter() {
    this.updateVerificationStorageEmitter.emit();
  }

  onGetAnnouncementEmitter() {
    this.updateAnnouncementEmitter.emit();
  }

  onInitSmarticoEventEmitter(data:any) {
    this.smarticoDataEventEmitter.emit(data);
  }

  onUpdateVIPEmitter() {
    this.updateVIPEmitter.emit();
  }

  onUpdateMemberGroupDetails(){
    this.updateMemberGroupDetailsEmitter.emit();
  }

  onJackpotEventEmitter(data:any) {
    this.jackpotDataEventEmitter.emit(data);
  }
  
}
