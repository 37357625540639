import { ClipboardModule } from '@angular/cdk/clipboard';
import { PopupAnnouncementComponent } from './popup-announcement/popup-announcement.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import '@angular/compiler';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { LanguageModalComponent } from './language-modal/language-modal.component';
import { SignupModalComponent } from './signup-modal/signup-modal.component';
import { ApiInterceptor } from '@core/interceptors/api.interceptor';
import { ApiErrorInterceptor } from '@core/interceptors/api-error.interceptor';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { SwalAlertComponent } from './swal-alert/swal-alert.component';
import { WalletModalComponent } from './wallet-modal/wallet-modal.component';

import { TransferWrapperModalComponent } from './transfer-wrapper-modal/transfer-wrapper-modal.component';
import { PreLoaderComponent } from './pre-loader/pre-loader.component';
import { BankWrapperModalComponent } from './bank-wrapper-modal/bank-wrapper-modal.component';
import { DigitOnlyModule } from '@uiowa/digit-only';

import { NgMarqueeModule } from 'ng-marquee';
import { TranslateModule } from '@ngx-translate/core';
import { CountDownComponent } from './count-down/count-down.component';
import { CountDownDetailsComponent } from './count-down-details/count-down-details.component';
import { FavoriteGameComponent } from './favorite-game/favorite-game.component';
import { ForgotPasswordModalComponent } from './forgot-password-modal/forgot-password-modal.component';
import { DemoNoticeComponent } from './demo-notice/demo-notice.component';
import { PopupDailyLimitOtpComponent } from './popup-daily-limit-otp/popup-daily-limit-otp.component';
import { PopupReferralComponent } from './popup-referral/popup-referral.component';
import { TinypreloaderComponent } from './tinypreloader/tinypreloader.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { VpnNoticeComponent } from './vpn-notice/vpn-notice.component';
import { SubSlotComponent } from './sub-slot/sub-slot.component';
import { BankModalComponent } from './bank-modal/bank-modal.component';
import { TransferModalComponent } from './transfer-modal/transfer-modal.component';
import { ShortNumberPipe } from 'app/pipes/short-number.pipe';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OrdinalDatePipe } from 'app/pipes/ordinal-date.pipe';
import { PushNotificationComponent } from './push-notification/push-notification.component';
import { PushNotificationCTAComponent } from './push-notification-cta/push-notification-cta.component';
import { SiteRouteComponent } from './site-route/site-route.component';
import { SignupFormComponent } from './signup-form/signup-form.component';
import { Layout1Component } from './signup-layouts/layout1/layout1.component';
import { SectionContainer1Component } from './signup-layouts/layout1/section-container1/section-container1.component';
import { SignupFormWrapperComponent } from './signup-layouts/layout1/signup-form-wrapper/signup-form-wrapper.component';
import { FormatNameInitialsPipe } from 'app/pipes/format-name-initials.pipe';
import { AppDownloadModalComponent } from './app-download-modal/app-download-modal.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MatBadgeModule } from '@angular/material/badge';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { RequestReceiptComponent } from './request-receipt/request-receipt.component';
import { PopupConnectionErrorComponent } from './popup-connection-error/popup-connection-error.component';

import { SafePipe } from 'app/pipes/safe.pipe';
import { CryptoWalletModalComponent } from './crypto-wallet-modal/crypto-wallet-modal.component';
import { FloatingRewardComponent } from './floating-reward/floating-reward.component';
import { SafeHtmlPipe } from 'app/pipes/safe-html.pipe';
import { EwalletVerificationModalComponent } from './ewallet-verification-modal/ewallet-verification-modal.component';
import { EwalletVerificationApprovalComponent } from './ewallet-verification-approval/ewallet-verification-approval.component';
import { EwalletVerificationRejectionComponent } from './ewallet-verification-rejection/ewallet-verification-rejection.component';
import { FileCompressionPipe } from 'app/pipes/file-compression.pipe';
import { FloatingKycNotificationComponent } from './floating-kyc-notification/floating-kyc-notification.component';
import { MarqueeNotificationModalComponent } from './marquee-notification-modal/marquee-notification-modal.component';

const modules = [
  AngularMultiSelectModule,
  CommonModule,
  HttpClientModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  MatCheckboxModule,
  MatCheckboxModule,
  MatButtonModule,
  MatInputModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatRadioModule,
  MatSelectModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatMenuModule,
  MatSidenavModule,
  MatToolbarModule,
  MatListModule,
  MatGridListModule,
  MatCardModule,
  MatStepperModule,
  MatTabsModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatChipsModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatDialogModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  ScrollingModule,
  NgbModule,
  DigitOnlyModule,
  NgMarqueeModule,
  TranslateModule,
  ClipboardModule,
  CarouselModule,
  QRCodeModule,
  NgxUsefulSwiperModule,
  MatBadgeModule
];

const directives = [];

const pipes = [ShortNumberPipe, OrdinalDatePipe, FormatNameInitialsPipe, SafePipe, SafeHtmlPipe, FileCompressionPipe];

const registrationPageLayouts = [
  SignupFormWrapperComponent,
  Layout1Component,
  SectionContainer1Component
];

const dialogs = [
  LoginModalComponent,
  SignupModalComponent,
  WalletModalComponent,
  LanguageModalComponent,
  TransferWrapperModalComponent,
  BankWrapperModalComponent,
  // VerificationModalComponent,
  CountDownDetailsComponent,
  // ClaimRewardModalComponent,
  ForgotPasswordModalComponent,
  DemoNoticeComponent,
  PopupReferralComponent,
  // MemberStatusModalComponent,
  PopupDailyLimitOtpComponent,
  SubSlotComponent,
  // ReceiptModalComponent,
  VpnNoticeComponent,
  BankModalComponent,
  TransferModalComponent,
  PushNotificationComponent,
  PushNotificationCTAComponent,
  SiteRouteComponent,
  AppDownloadModalComponent,
  PopupConnectionErrorComponent,
  CryptoWalletModalComponent,
  EwalletVerificationModalComponent,
  EwalletVerificationApprovalComponent,
  EwalletVerificationRejectionComponent,
  MarqueeNotificationModalComponent,
];

const components = [
  ...directives,
  ...pipes,
  ...dialogs,
  ...registrationPageLayouts,
  SwalAlertComponent,
  // TransferModalComponent,
  PreLoaderComponent,
  // BankModalComponent,
  TransferWrapperModalComponent,
  BankWrapperModalComponent,
  CountDownComponent,
  CountDownDetailsComponent,
  FavoriteGameComponent,
  PopupAnnouncementComponent,
  DemoNoticeComponent,
  TinypreloaderComponent,
  VpnNoticeComponent,
  SignupFormComponent,
  RequestReceiptComponent,
  FloatingRewardComponent,
  FloatingKycNotificationComponent,
];

@NgModule({
  declarations: [
    ...components
  ],
  exports: [
    ...components,
    ...modules,
    TranslateModule
  ],
  imports: [
    ...modules
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
    {
      provide: MatDialogRef,
      useValue: {}
    },
  ],
  entryComponents: [
    ...dialogs
  ]
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}
