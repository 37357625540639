// Service
import { EventEmitterService } from '@core/services/event-emitter.service';
import { NumberVerificationHttpService } from '@core/services/number-verification-http.service';
// Angular
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
// Component
import { PopupDailyLimitOtpComponent } from '@shared/popup-daily-limit-otp/popup-daily-limit-otp.component';

@Component({
  selector: 'app-verification-modal',
  templateUrl: './verification-modal.component.html',
  styleUrls: ['./verification-modal.component.scss']
})
export class VerificationModalComponent implements OnInit {

  form: FormGroup;
  messages$ = this.numberVerificationHttpService.messages$;
  isSuccess = this.numberVerificationHttpService.isSuccess;
  toCloseDialog = false;
  counter: number;
  timer: any;
  showSeconds = false;
  disabled = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      can_request_otp: boolean,
      new_mobile_number: string,
      request_type: string,
    },
    public dialogRef: MatDialogRef<VerificationModalComponent>,
    private numberVerificationHttpService: NumberVerificationHttpService,
    private eventEmitterService: EventEmitterService,
    public dialog: MatDialog,
    ) { }

  ngOnInit() {
    this.data.request_type = this.data?.request_type ?? 'phone_validation';
    this.formInit();
    if (sessionStorage.getItem('OTP_timer') !== null) {
      this.onTimer(Number(sessionStorage.getItem('OTP_timer')));
    }
  }

  onCloseDialog(closeDialog: boolean) {
    if (closeDialog) {
      this.dialogRef.close(this.toCloseDialog);
      this.eventEmitterService.onReloadProfile();
    }
  }

  onRequestOTP() {
    if (this.data.can_request_otp) {
      this.disabled = 'disabled';
      const newNumber = this.data.new_mobile_number ? this.data.new_mobile_number : null;
      const requestType = this.data.request_type;
      this.numberVerificationHttpService.requestOTP(newNumber, requestType).subscribe(res => {
        this.onTimer(res.otp_request_interval);
        this.isSuccess = true
      });
    } else {
      this.openDialogBy(PopupDailyLimitOtpComponent);
    }
  }

  onVerify() {
    this.numberVerificationHttpService.verifyOTP(this.form.value).subscribe(() => {
      this.toCloseDialog = true;
      localStorage.setItem('verified_number', 'true');
    });
  }

  onTimer(startTime: number) {
    this.disabled = 'disabled';
    this.counter = startTime;
    this.eventEmitterService.onVerificationTimerEmitter();
    window.clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.counter--;
      if (this.counter === 0) {
        window.clearInterval(this.timer);
        this.disabled = '';
      }
    }, 1000);
  }

  private openDialogBy(componentRef: any) {
    this.dialog.open(componentRef, {width: 'auto'});
  }

  private formInit() {
    this.form = new FormGroup({
      otp: new FormControl(null, [Validators.required, Validators.pattern(/([0-9]{6})/g)]),
      request_type: new FormControl(this.data.request_type),
    });
  }

}
