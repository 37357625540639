<form class="row" [formGroup]="form" (ngSubmit)="submit()" id="transfer-modal-form" name="transfer-modal-form">
  <div class="form-process">
    <div class="css3-spinner">
      <div class="css3-spinner-scaler"></div>
    </div>
  </div>
  <div class="col-sm-3 col-form-label" *ngIf="!isPerProvider">
    <label for="fitness-form-name">{{ 'Transfer From - To' | translate }}: </label>
  </div>
  <div class="form-group col-sm-9" *ngIf="!isPerProvider">
    <div class="row">
      <div class=" col-md-6">
        <select class="form-control required" formControlName="transfer_from" [ngClass]="{'is-invalid dropdown-invalid': submitted && !transferToFromValidate }" (change)="onTransferChange($event, 'from')">
          <option [value]="null" disabled>{{ 'Please Select' | translate }}</option>
          <ng-container *ngIf="walletBalance | async as memberWalletBalance">
            <option value="0">{{ 'Main Wallet' | translate }} ({{ memberWalletBalance | number : '1.2-2' }})</option>
          </ng-container>
          <ng-container *ngFor="let item of providersDropdown">
           <option [value]="item.id" [disabled]="item.status === 2">{{ item.name}} ({{ item.balance }})</option>
          </ng-container>
        </select>
      </div>
      <div class="col-md-6 col_last">
        <select class="form-control required" formControlName="transfer_to" [ngClass]="{'is-invalid dropdown-invalid': submitted && !transferToFromValidate }" (change)="onTransferChange($event)">
          <option [value]="null" disabled>{{ 'Please Select' | translate }}</option>
          <ng-container *ngIf="walletBalance | async as memberWalletBalance">
            <option value="0">{{ 'Main Wallet' | translate }} ({{ memberWalletBalance | number : '1.2-2' }})</option>
          </ng-container>
          <ng-container *ngFor="let item of providersDropdown">
            <option [value]="item.id" [disabled]="item.status === 2">{{ item.name}} ({{ item.balance }})</option>
          </ng-container>
        </select>
      </div>
      <div *ngIf="submitted && !transferToFromValidate" class="text-danger col-md-12">{{ 'Transfer to and transfer from must be different' | translate }}</div>
    </div>
  </div>

  <div class="col-sm-3 col-form-label" *ngIf="isPerProvider">
    <label>{{ 'Balance' | translate }}:<br>({{ currency?.code }})</label>
  </div>
  <div class="col-sm-9 balance-text" *ngIf="isPerProvider">
    <p> {{ balance | number : '1.2-2' }} &nbsp;</p>
  </div>

  <div class="col-sm-3 col-form-label">
    <label>{{ 'Transfer Amount' | translate }}: </label>
  </div>

    <div class="form-group col-sm-9">
      <div class="input-group">
        <span class="input-group-append">
          <span class="input-group-append">
            <span class="input-rounded-left input-group-currency"> {{ currency.code }} </span>
          </span>
        </span>
        <input type="text" class="form-control required left-border-radius-none" value="" placeholder="{{ 'Enter Amount' | translate }}" formControlName="amount"
          [ngClass]="{'is-invalid': submitted && form.controls.amount.errors }" digitOnly decimal="true" decimalSeparator="." pattern="^\d+(\.\d{1,2})?$">
      </div>
      <div class="form-msg mt-3" *ngIf="showIDRRate | async">
          <div style="display:inlin; color: #007bff !important;"> {{ 'Exchange Rate' | translate }} <br> IDR 1:0.2 {{ providerName | uppercase }} {{ 'Credits' | translate }} </div>
      </div>
    </div>
    <div class="col-sm-3 col-form-label"> </div>
    <div class="form-group col-sm-9 col-xs-12">
      <div class="btn-group btn-group-toggle d-flex">
        <label *ngFor="let amount of amountButton" class="btn btn-outline-secondary px-4 font-weight-semibold ls0 nott"
          (click)="onChangeAmount(amount)">{{ currency.code }} {{ amount | number : '1.0' }} </label>
      </div>
    </div>
    <ng-container  *ngIf="form.value.transfer_to > 0 && promoList.length > 0">
      <div class="col-sm-3 col-form-label">
        <label>{{ 'Promotion' | translate }} :</label>
      </div>
      <div class="form-group col-sm-9">
        <select class="form-control required dropdown-custom" formControlName="promotion_id" [ngClass]="{'is-invalid dropdown-invalid': form.controls.promotion_id.errors }" (change)="onSelectPromotion($event)">
          <!-- <select class="form-control required" formControlName="promotion_id" #promotionRef> BEFORE-->
          <option value="0" selected>{{ 'No Promotion' | translate }}</option>
          <option [value]="item.id" *ngFor="let item of promoList; let i = index" >
            {{ item.name === null ? (('Promotion' | translate) + ' ' + i) : item.name }}
          </option>
        </select>
        <div class="col-sm-12 col-form-label pl-0 pb-0" *ngIf="selectedPromotion">
            <label class="turn-over-text mb-0">{{ 'Turnover Requirement' | translate }}: </label><br>
            <label class="turn-over-text mb-0">
              {{ currency.code }}
              {{ onMultiplicand(form.value.amount, selectedPromotion) }}
              x {{ selectedPromotion.target_multiplier }} = {{ currency.code + ' ' + (onTotalTurnOver(form.value.amount, selectedPromotion) | number: '1.2-2') }}
            </label><br>
            <label class="turn-over-text mt-0">{{ 'Bonus' | translate }}: {{ currency?.code + ' ' + onBonusAmount(form.value.amount, selectedPromotion) }}</label>
        </div>
      </div>
    </ng-container>
    <div class="col-sm-3 col-form-label"> </div>
    <div class="col-sm-6">
      <button type="submit" class="button-orange save-button-ml">{{ 'Submit' | translate }}</button>
    </div>
    <div class="col-sm-3 skip-text">
      <p *ngIf="isPerProvider" (click)="onCloseDialog()"> {{ 'Skip' | translate }} &gt;</p>
    </div>
</form>

<div class="form-group col-sm-12" *ngIf="!isPerProvider">
  <app-wallet-modal *ngIf="providersDropdown.length > 0"></app-wallet-modal>
</div>

<app-swal-alert [success]="isSuccess" [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></app-swal-alert>
